import React, { useEffect, useState } from "react";
import styles from "./InnerBlog.module.css";
import BlogInfo from "../../Components/BlogInfo/BlogInfo";
import InnerCards from "../../Components/InnerCards/InnerCards";
import SpecialitiesCard from "../../Components/SpecialitiesCard/SpecialitiesCard";
import { open_api } from "../../Utils/network";
import { useParams } from "react-router-dom";
import Skeleton from "react-loading-skeleton";

function InnerBlog() {
  const [blogData, setBlogData] = useState();
  const [blogs, setBlogs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const { slug } = useParams();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [slug]);

  useEffect(() => {
    if (slug) {
      blogDetailes(slug);
      // getBlogComments(id);
    }
  }, [slug]);

  const blogDetailes = (slug) => {
    try {
      open_api
        .get(`blog/blog_pages/${slug}/`)
        .then((res) => {
          // console.log(res?.data);
          setBlogData(res?.data);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  //   get blog listing

  useEffect(() => {
    getBlogDetails();
  }, []);

  const getBlogDetails = () => {
    open_api
      .get(
        `blog/blog_pages?fields=id%2Ctitle%2Cpublished_date%2Cintro%2Cheader_image_url%2Ctags%2Cread_time%2Cauthors&type=blogs.BlogPage`
      )
      .then((res) => {
        if (res?.status == 200 || res?.status == 201 || res?.status == 204) {
          setBlogs(res?.data?.items);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  if (isLoading) {
    return (
      <div className={styles.skeletonContainer}>
        {/* Skeleton for Header */}
        <Skeleton height={40} width={`60%`} />

        {/* Skeleton for Content */}
        <div className={styles.skeletonBody}>
          <Skeleton height={20} count={5} />
          <Skeleton height={200} width={`100%`} style={{ marginTop: "1rem" }} />
        </div>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <section>
        <BlogInfo data={blogData} />
      </section>
      {/* <section className={styles.specilistCard}>
        <h1 className={styles.innerh1}>Workshops by </h1>
        <ExpertDetailsWorkshop slug={expertDetails?.id} />
      </section> */}
      {/* <section className={styles.innerCards}>
                <h3>YOU MAY ALSO LIKE</h3>
                <h2>TRENDING</h2>
                <div className={styles.innerCardContainer}>
                    {blogs.map((item) => {
                        return (
                            <InnerCards data={item}/>
                        )
                    })}
                </div>
            </section> */}
    </div>
  );
}

export default InnerBlog;
const data = [
  { id: 1, title: "Baby sleep and needs" },
  { id: 2, title: "Baby sleep and needs" },
  { id: 3, title: "Baby sleep and needs" },
  { id: 4, title: "Baby sleep and needs" },
  {
    id: 5,
    title: "Baby sleep and needs",
  },
];
