import React from "react";
import LoginCard from "../../Components/Login/LoginCard";
import "./LoginPage.css"; // Importing the CSS for styling
import myburgoLogo from "../../Assets/login/myburgoLogo.svg";
import phoneMockup from "../../Assets/login/phonemockup.svg";
import playstore from "../../Assets/login/googleplay.svg";
import appstore from "../../Assets/login/applestore.svg";
import useAuth from "../../Components/Hook/useAuth";
import { useNavigate, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";

const LoginPage = () => {
  const { isAuthenticated } = useAuth();
  const [enablePayButton, setEnablePayButton] = useState(false);
  const [isUserInfoExists, setIsUserInfoExists] = useState(false);
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const [bearerLocalToken, setBearerToken] = useState("");
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";
  const navigate = useNavigate();
  useEffect(() => {
    if (isAuthenticated && shouldRedirect) {
      navigate(from, { replace: true });
    }
  }, [isAuthenticated, shouldRedirect, navigate]);

  const handlePayEnable = (enable) => {
    setEnablePayButton(enable);
  };

  const handleUserInfoExists = (exists) => {
    setIsUserInfoExists(exists);
  };

  const handleValidationComplete = () => {
    setShouldRedirect(true);
  };

  const handleBearerToken = (token) => {
    setBearerToken(token);
  };

  return (
    <>
      <Helmet>
        <title>MyBurgo - Parenting App | Pregnancy App | SelfCare App</title>
        <meta
          name="description"
          content="MyBurgo is India's comprehensive parenting app offering expert guidance, interactive workshops, personalized resources, and community support for parents and pregnant women. Empower your parenting journey with child development tools, curated recipes, mental health support, and more."
        />
      </Helmet>
      <div className="login-page-container">
        {/* Left Section - Hidden on Mobile */}
        <div className="left-section">
          <img className="logo" src={myburgoLogo} alt="MyBurgo Logo" />
          <h2>your ultimate parenting partner</h2>
          <div className="phone-mockup-container">
            <img
              className="phone-mockup"
              src={phoneMockup}
              alt="Phone Mockup"
            />
          </div>
          <div className="app-store-buttons">
            <img
              className="app-store-button"
              src={playstore}
              alt="Google Play Store"
            />
            <img
              className="app-store-button"
              src={appstore}
              alt="Apple App Store"
            />
          </div>
        </div>

        {/* Right Section - Login Card */}
        <div className="right-section">
          <h1>Login</h1>
          <LoginCard
            setEnablePayButton={handlePayEnable}
            bypass={false}
            updateIsUserInfoExists={handleUserInfoExists}
            updateBearerToken={handleBearerToken}
            from={from}
          />
        </div>
      </div>
    </>
  );
};

export default LoginPage;
