import React, { useEffect } from "react";
import styles from "./index.module.css";
import { Helmet } from "react-helmet";

function CancellationandRefundPolicy() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Helmet>
        <title>MyBurgo - Parenting App | Pregnancy App | SelfCare App</title>
        <meta
          name="description"
          content="MyBurgo is India's comprehensive parenting app offering expert guidance, interactive workshops, personalized resources, and community support for parents and pregnant women. Empower your parenting journey with child development tools, curated recipes, mental health support, and more."
        />
      </Helmet>
      <div className={styles.mainContainer}>
        <h1> Cancellation and Refund Policy</h1>
        <p>
          <strong>Effective Date:</strong> 1st June 2024
        </p>
        <p>
          {" "}
          Thank you for choosing MyBurgo, a platform owned and operated by
          Velvin Technologies Pvt Ltd, located at B-303, Mangalya, Marol Maroshi
          Road, Andheri (East), Mumbai-400059, India.
        </p>

        <p>
          This Cancellation and Refund Policy outlines the terms and conditions
          governing the cancellation of course purchases and the process for
          requesting refunds. By purchasing any course on www.myburgo.com, you
          agree to the following terms:
        </p>

        <h3>No Cancellation Policy</h3>
        <p>
          {" "}
          Once a course purchase is made on www.myburgo.com, it is considered
          final. We do not accept any cancellations for course purchases under
          any circumstances.
        </p>

        <h3>No Refund Policy</h3>
        <p>
          All course purchases are non-refundable. We do not offer refunds for
          any courses purchased through our platform, regardless of the reason.
        </p>

        <h3>Course Access</h3>
        <p>
          Upon successful purchase, you will have immediate access to the course
          materials. Please ensure that you have thoroughly reviewed the course
          details and requirements before making a purchase.
        </p>
        <h3>Exceptions</h3>
        <p>
          In exceptional cases where the course content is inaccessible or there
          are technical issues preventing access, please contact our support
          team at
          <a href={`mailto: customercare@myburgo.com`}>
            {" "}
            customercare@myburgo.com
          </a>{" "}
          for assistance. While we do not guarantee refunds, we will make every
          effort to resolve technical issues promptly.
        </p>

        <h3>Contact Information</h3>
        <p>
          If you have any questions or need further clarification regarding our
          cancellation and refund policy, please contact us at:
        </p>
        <p>
          <strong>Email:</strong>{" "}
          <a href={`mailto: customercare@myburgo.com`}>
            {" "}
            customercare@myburgo.com
          </a>
        </p>
        <p>
          {" "}
          <strong>Address:</strong> B-303, Mangalya, Marol Maroshi Road, Andheri
          (East), Mumbai-400059, India
        </p>
        <p>We appreciate your understanding and thank you for your support.</p>
        <p>
          <strong>Velvin Technologies Pvt Ltd</strong>{" "}
        </p>
      </div>
    </>
  );
}

export default CancellationandRefundPolicy;
