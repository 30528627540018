import React, { useEffect } from "react";
import styles from "./index.module.css";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

function PrivacyPolicyPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Helmet>
        <title>MyBurgo - Parenting App | Pregnancy App | SelfCare App</title>
        <meta
          name="description"
          content="MyBurgo is India's comprehensive parenting app offering expert guidance, interactive workshops, personalized resources, and community support for parents and pregnant women. Empower your parenting journey with child development tools, curated recipes, mental health support, and more."
        />
      </Helmet>
      <div className={styles.mainContainer}>
        <h1>Privacy Policy</h1>
        <p>
          <strong>Last Updated:</strong> 1 August 2024
        </p>

        <h2>Introduction</h2>
        <p>
          Velvin Technologies Private Limited, a company incorporated under the
          laws of India, having its registered address at B-303, Mangalya, Marol
          Maroshi Road, Andheri (East), Mumbai-400059, India (“Company”, “we”,
          “us” or “our”) manages and operates a website located at the URL{" "}
          <a href="www.myburgo.com">www.myburgo.com</a> and a mobile application
          under the brand name MyBurgo (collectively referred to as the
          “Portal”) where you can place orders to purchase the Services offered
          by us to the users of the Portal (“User” or “Users” or “you” or
          “your”).
        </p>

        <p>
          This privacy policy (“Privacy Policy”), together with the Terms of Use
          (available at{" "}
          <a href="https://www.myburgo.com/terms-and-condition">
            https://www.myburgo.com/terms-and-condition
          </a>
          ) governs your use of the Portal and describes our policies and
          procedures on the collection, use, disclosure, processing, transfer,
          and storage of the information provided to us by you. By using,
          browsing, accessing, or purchasing from the Portal you agree to be
          bound by the terms of this Privacy Policy and consent to the
          collection, storage, possession, dealing, handling, sharing,
          disclosure or transfer of your information in accordance with the
          terms of the Privacy Policy. We shall not use the User’s information
          in any manner except as provided under this Privacy Policy.
          Capitalised terms used herein if not defined shall have the same
          meaning as ascribed to them under the Terms.
        </p>

        <p>
          This document is an electronic record and is governed by the
          provisions under the Information Technology Act, 2000 and rules made
          thereunder as may be applicable, and the amended provisions pertaining
          to electronic records in various statutes as amended by the
          Information Technology Act, 2000. This electronic record is generated
          by a computer system and does not require any physical or digital
          signatures.
        </p>

        <p>
          This Privacy Policy sets out the type of information collected from
          the Users, including the nature of the Sensitive Personal Data or
          Information (defined below), the purpose, means and modes of usage of
          such information and how and to whom such information shall be
          transferred or disclosed by us. You may, at any time withdraw your
          consent for collection and use of your information including Personal
          Information (defined below) or Sensitive Personal Data or Information.
          However, please note that if you withdraw your consent, we may no
          longer be able to provide you with the corresponding service for which
          you have withdrawn your consent. It is hereby clarified that your
          decision to withdraw your consent will not affect the processing of
          Personal Information based on your previous consent prior to the
          withdrawal.
        </p>

        <p>
          Please take a moment to familiarize yourself with our Privacy Policy.
          If you do not agree with any provisions of the Terms or this Privacy
          Policy, we advise you to not use or access the Portal.
        </p>

        <h2>1. Type of Information Collected</h2>
        <ul>
          <li>
            You may be asked to provide your information anytime you visit,
            access, use or browse the Portal. We may share information and use
            it consistent with the provisions of this Privacy Policy. We may
            also combine it with other information to provide and improve our
            Services, content and advertising.
          </li>
          <li>
            You agree to provide information, which shall be true, correct, up
            to date and accurate. You may access, amend, alter or require
            deletion of your information partially or fully by contacting us at
            <a href="mailto:hello@myburgo.com">hello@myburgo.com</a>.
          </li>
          <li>
            We may collect a variety of information from you, including your
            name, password, mailing address, phone number, email address and
            contact preferences, including any other usage and interaction
            details with us or our affiliates. We may collect such information
            which when combined with other pieces of information available with
            us could reasonably allow you to be identified (“Personal
            Information”). Personal Information shall include but is not limited
            to full name, personal contact numbers, residential address, email
            address, gender or date of birth of the Users and/or their child.
          </li>
          <li>
            We may also collect, receive, process or store certain sensitive
            personal data or information consisting of, but not limited to:
            <ul>
              <li>Password;</li>
              <li>
                Financial information such as bank account or credit card or
                debit card or other payment instrument details;
              </li>
              <li>Physical, physiological and mental health condition;</li>
              <li>
                Any detail relating to the above Personal Information categories
                as provided to us for providing service;
              </li>
              <li>
                Any of the information received under above Personal Information
                categories by us for processing, stored or processed under
                lawful contract or otherwise;
              </li>
              <li>Biometric information;</li>
              <li>
                Any detail relating to the above Personal Information categories
                as provided to us for providing service; and Any of the
                information received under the above Personal Information
                categories by us for processing, storing, under lawful contract,
                or otherwise (collectively, referred to as “Sensitive Personal
                Data or Information”).
              </li>
            </ul>
          </li>
          <li>
            We automatically collect certain information about your device and
            usage of our Services. This may include:
            <ul>
              <li>IP address</li>
              <li>Browser type and version</li>
              <li>Device type</li>
              <li>Operating system</li>
              <li>Access times and dates</li>
              <li>Referring website addresses</li>
              <li>
                Pages you visit and actions you take on our website and app
              </li>
            </ul>
          </li>
          <li>
            We use cookies and similar tracking technologies to track the
            activity on our Services and hold certain information. You can
            instruct your browser to refuse all cookies or to indicate when a
            cookie is being sent. However, if you do not accept cookies, you may
            not be able to use some portions of our Services.
          </li>
        </ul>

        <h2>2. Use of Information Collected</h2>
        <p>
          We may collect, use or process your information including Personal
          Information and Sensitive Personal Data or Information for the
          following purposes
        </p>
        <ul>
          <li>
            For creating and giving you access to your registered account on the
            Portal.
          </li>
          <li>
            To develop, deliver, process and improve our Services, content in
            order to personalize and improve your experience.
          </li>
          <li>
            To develop new products, services, features, and functionality
          </li>
          <li>
            To inform you about our Services, offers, updates, upcoming events,
            including providing you information in relation to order
            confirmations, invoices, technical notices, security alerts.
          </li>
          <li>To process your transactions and manage your orders</li>
          <li>
            For internal analytical and research purposes such as auditing, data
            analysis and research to improve Services and customer
            communications.
          </li>
          <li>
            To meet any legal or regulatory requirement or comply with a request
            from any governmental or judicial authority.
          </li>
          <li>
            To resolve any request, dispute, grievance or complaint raised by
            you in relation to your use of the Portal.
          </li>
          <li>
            To detect or monitor any fraudulent or illegal activity on the
            Portal.
          </li>
        </ul>

        <h2>3. Disclosure of Information Collected</h2>
        <ul>
          <li>
            We do not sell, trade, or otherwise transfer to outside parties your
            personalinformation unless we provide users with advance notice.
            This does not include website hosting partners and other parties who
            assist us in operating our website, conducting our business, or
            serving our users, so long as those parties agree to keep this
            information confidential. We may also release information when its
            release is appropriate to comply with the law, enforce our site
            policies, or protect ours or others' rights, property or safety. For
            instance, we may share your information with third party payment
            gateway providers to process transactions on the Portal. By using
            the Portal, you consent to any such disclosure of your information
            with third party service providers. We ensure that such third party
            service providers are bound by reasonable confidentiality
            obligations and/or use, maintain and follow generally accepted
            industry and security standards with respect to such information.
          </li>
          <li>
            We may also disclose your information when such disclosure is
            requisitioned under any law or judicial decree or when we, in our
            sole discretion, deem it necessary in order to protect our rights or
            the rights of other Users, to prevent harm to persons or property,
            to fight fraud and credit risk
          </li>
          <li>
            We may also disclose or transfer your information to any third party
            as a part of reorganization or a sale of the assets, division or
            transfer of a part or whole of us. We shall ensure that third party
            to which we transfer or sell our assets will have appropriate
            confidentiality and security measures, at least as protective as
            those described in this Privacy Policy, to handle your Personal
            Information. You will have the opportunity to opt out of any such
            transfer if the new entity's planned processing of your information
            differs materially from that set forth in this Privacy Policy.
          </li>
          <li>
            A third party payment gateway provider may be required to collect
            certain financial information from you including, but not restricted
            to, your credit/debit card number or your bank account details
            (collectively referred to as “Financial Information”). All Financial
            Information collected from you by such third party payment gateway
            providers will be used only for billing and payment processes. The
            Financial Information collected from you is transacted through
            secure digital platforms of approved payment gateways, which are
            under encryption, thereby complying with reasonably expected
            technology standards. The verification of the Financial Information
            shall be accomplished only by you through a process of
            authentication in which we shall have no role to play and hence, we
            shall bear no liability in relation to the same. We shall neither be
            liable nor responsible for any actions or inactions of the third
            party payment gateway providers or any breach of conditions,
            representations and warranties given by them. We shall also not be
            obligated to mediate or resolve any dispute or disagreement between
            you and such third party payment service providers.
          </li>
          <li>
            While we make best efforts to ensure that your information including
            Personal Information, Financial Information and Sensitive Personal
            Information or Data is duly protected by undertaking security
            measures prescribed under applicable laws, you are strongly advised
            to exercise reasonable discretion while providing Personal
            Information or Financial Information while using the services given
            that the Internet is susceptible to security breaches.
          </li>
          <li>
            Your information may also be transferred, stored or processed in any
            country other than the country in which you access the Portal. For
            purposes of sharing or disclosing data in accordance with the
            Privacy Policy, we reserve the right to transfer your information
            outside of your country. By using the (Portal, you consent to such
            transfer of your information outside of your country and also within
            our affiliates, subsidiaries and partners whose personnel and
            subcontractors, provided they have agreed to ensure the same level
            of data protection as prescribed under this Privacy Policy and the
            data protection laws of India. The information may be transferred in
            the event it is necessary for the performance of the services,
            operation of the Portal and provision of Services as agreed upon
            between us and the third party.
          </li>
        </ul>

        <h2>4. Security</h2>
        <ul>
          <li>
            The security of your Personal Information is important to us. We
            have implemented security policies, rules and technical measures, as
            required under applicable law including firewalls, transport layer
            security and other physical and electronic security measures to
            protect the Personal Information that it has under its control from
            unauthorized access, improper use or disclosure, unauthorized
            modification and unlawful destruction or accidental loss. When you
            submit your information on the Portal, your information is protected
            through our security systems. Please note that we use
            industry-recognized standards as per applicable laws, rules and
            regulations.
          </li>
          <li>
            Your information is contained within secured networks and is only
            accessible by a limited number of authorized persons who have access
            rights to such systems or otherwise require such information for the
            purposes provided in this Privacy Policy. These authorized persons
            are also under an obligation to keep such information confidential.
          </li>
          <li>
            Although we make best possible efforts to transmit and store all the
            information provided by you in a secure operating environment that
            is not open to public, you understand and acknowledge that there is
            no such thing as complete security and we do not guarantee that
            there will be no unintended disclosures of any information and
            potential security breaches. You agree not to hold us responsible
            for any breach of security or for any action of any third parties
            that receive your Personal Information or events that are beyond our
            reasonable control including, acts of government, computer hacking,
            unauthorized access to computer data and storage device, computer
            crashes, breach of security and encryption, etc.
          </li>
        </ul>

        <h2>5. Cookies Policy</h2>
        <ul>
          <li>
            Due to the communications standards on the Internet, when you visit,
            access or browse the Portal, we automatically receive the uniform
            resource locator of the site from which you visit, access or browse
            the Portal, details of the sites you visit on leaving the Portal,
            the internet protocol (“IP”) address of each User’s computer
            operating system, type of web browser the User is using, email
            patterns, and the name of the User’s internet service provider. This
            information is used solely to analyse overall User trends and to
            help us improve our services. Please note that the link between the
            User’s IP address and the User’s personally identifiable information
            is not shared with third parties without User’s permission or except
            when required by law or to provide or facilitate the User with the
            services. Notwithstanding the above, the User acknowledges that we
            reserve the right to share some of the aggregate findings, including
            the personal information provided by the Users in an unidentifiable,
            aggregate form, and not the specific data with advertisers,
            sponsors, investors, strategic partners, and others in order to help
            grow the business. The amount of information sent to us depends on
            the settings of the web browser used by the User to access the
            Portal. The User may refer to the browser used, if the User wishes
            to learn what information is provided to us.
          </li>
          <li>
            The Portal uses temporary cookies to store certain data. We do not
            store Personal Information in the cookies. Information collected by
            us, by any means whatsoever, that does not personally identify the
            User as an individual (such as patterns of utilization described
            above) is exclusively owned by us and may be used by us and third
            party service providers for technical administration of the Portal,
            user administration, research, development, and other purposes.
          </li>
          <li>
            You understand that you may set or amend your web browsers to delete
            or disable cookies. If you choose to disable cookies on your
            computer or mobile telecommunication device, it may impair, degrade
            or restrict access to certain areas of the Portal.
          </li>
          <li>
            We may allow other companies or entities to serve advertisements to
            you. These companies or entities include third party advertisement
            servers, advertisement agencies, and advertisement technology
            vendors. We may target some advertisements to you that fit a certain
            general profile. We do not use Personal Information to target
            advertisements to you. In the course of serving advertisements or
            optimizing the services to its Users, we may allow authorised third
            parties to place or recognize a unique cookie on the User’s browser.
          </li>
          <li>
            We may keep records of all communications, both voice telephone
            calls and non-voice digital chats and emails received from and made
            to Users for the purpose of administration of services, research and
            development, training, business intelligence, business development,
            or for User administration. We may share such telephone records with
            third parties when required by law or when required to provide or
            facilitate the User with the services.
          </li>
          <li>
            You consent to our reproduction/publishing of all testimonials and
            reviews given by you on the Portal in relation to the Services. You
            agree that we may edit the testimonials and reviews provided by you
            and reproduce/publish such edited or paraphrased versions of the
            testimonials and reviews on the Portal. If the User has any concerns
            with the reproduction/publication of any testimonial or review
            provided by you, the User may contact us at{" "}
            <a href="mailto:hello@myburgo.com">hello@myburgo.com</a>.
          </li>
        </ul>

        <h2>6. Opt-Out Policy</h2>
        <ul>
          <li>
            The third party service providers with whom we may share information
            provided by you are not permitted to market their own services or
            send promotional e-mails or engage in promotional communication with
            you. We provide you with the opportunity to opt-out of receiving
            non-essential, promotional, or marketing-related communication from
            itself or its partners.
          </li>
          <li>
            If you wish to remove your contact information from all our mailing
            lists and newsletters, you can contact us at{" "}
            <a href="mailto:hello@myburgo.com">hello@myburgo.com</a>. We reserve
            the right to limit membership based on availability of contact
            information. All Users will be notified by email prior to any
            actions taken.
          </li>
        </ul>

        <h2>7. Retention of Information</h2>
        <p>
          We will retain your information and any data for the period necessary
          to fulfil the purposes outlined in this Privacy Policy unless a longer
          retention period is required or permitted under the applicable law.
        </p>

        <h2>8. Modification</h2>
        <p>
          We reserve the right to amend this Privacy Policy at any time. The
          Privacy Policy, as and when modified, shall be updated on the Portal.
          We encourage you to review this Privacy Policy whenever you visit our
          Portal to understand how your Personal Information is used.
        </p>

        <h2>9. Grievance Redressal</h2>
        <p>
          In case, you have any questions, grievance or complaints about this
          Privacy Policy, or about the Platform, you may contact our grievance
          officer on the below mentioned details:
        </p>
        <ul>
          <li>
            Email: <a href="mailto:hello@myburgo.com">hello@myburgo.com</a>
          </li>
          <li>Phone: 7710048212</li>
        </ul>

        <h2>10. Governing Law</h2>
        <p>
          The terms of this Privacy Policy shall be governed and construed in
          accordance with the laws of India. Any dispute regarding or arising
          out of this Privacy Policy shall be subject to the exclusive
          jurisdiction of the courts in Mumbai, Maharashtra.
        </p>

        <h2>Contact Us</h2>
        <p>
          If you have any questions or concerns about this Privacy Policy,
          please contact us at:
        </p>

        <h2>Severability</h2>
        <p>
          Whenever possible, each section of this Privacy Policy shall be
          interpreted in a manner so as to be valid under applicable law.
          However, in the event of any provision is held to be prohibited or
          invalid, such provision shall be ineffective only to the extent of
          such prohibition or invalidity, without invalidating the remainder of
          such provision or other remaining provisions of this Privacy Policy.
        </p>

        <h2>Contact Us</h2>
        <p>
          If you have any questions or concerns about this Policy or our data
          practices, or if you would like to exercise your data protection
          rights, please contact us at:
        </p>
        <p>Velvin Technologies Private Limited</p>
        <p>
          Email Id: <a href="mailto:hello@myburgo.com">hello@myburgo.com</a>
        </p>
        <p>Contact Days: Monday to Saturday (10:00am to 6:00pm)</p>
      </div>
    </>
  );
}

export default PrivacyPolicyPage;
