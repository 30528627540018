import React from "react";
import "./CongratulationsBanner.css";

const CongratulationsBanner = ({ priceSymbol, discount }) => {
  return (
    <div className="congratsBanner">
      <span role="img" aria-label="party popper">
        🎉
      </span>
      {priceSymbol}
      {discount} saved on the total!
    </div>
  );
};

export default CongratulationsBanner;
