import React, { useState, useEffect } from "react";
import {
  Dialog as MuiDialog,
  DialogContent,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import "./ConfirmationDialog.css";
import { useNavigate } from "react-router-dom";
import successAnimation from "../../Assets/lottie/successlottie.json";
import failureAnimation from "../../Assets/lottie/failureLottie.json";
import Lottie from "react-lottie-player";
import phoneMockup from "../../Assets/login/phonemockup.svg";
import playstore from "../../Assets/login/googleplay.svg";
import appstore from "../../Assets/login/applestore.svg";
import { isProduction } from "../../Utils/network";

const ConfirmationDialog = ({
  open,
  onClose,
  image,
  title,
  description,
  status,
  statusText,
  statusDescription,
  statusIcon,
  backgroundColor,
  bookingsPage,
  redirect,
  countdownDuration,
  whatsappLink,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  // State to manage the countdown timer
  const [countdown, setCountdown] = useState(countdownDuration);

  const lottieAnimation =
    status === "success" ? successAnimation : failureAnimation;

  const baseurl = isProduction
    ? "https://www.myburgo.com"
    : "https://development.myburgo.com";

  return (
    <MuiDialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      fullScreen={fullScreen}
    >
      <DialogContent
        className="confirmation-dialog"
        style={{ backgroundColor }}
      >
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "black",
            zIndex: 2,
          }}
        >
          <CloseIcon />
        </IconButton>
        <div className="confirmation-dialog-content">
          <Lottie
            loop={false}
            play
            animationData={lottieAnimation}
            style={{ height: 150 }}
          />
          <h2>{statusText}</h2>
          <p className="confirmation-heading">{statusDescription}</p>
          {status === "success" ? (
            <p className="confirmation-text">
              We'll email you the details shortly.
            </p>
          ) : (
            <></>
          )}
          {status != "failure" && whatsappLink != null ? (
            <>
              <p className="countdown-text">
                Join the WhatsApp group using the link below:
              </p>
              <a
                href={whatsappLink}
                target="_blank"
                rel="noopener noreferrer"
                className="link-button-wrapper"
              >
                <button className="link-button">Join now</button>
              </a>
            </>
          ) : (
            <>
              {status != "failure" ? (
                <>
                  {bookingsPage ? (
                    redirect.includes("session-data") ? (
                      <>
                        <p className="countdown-text">
                          To help us prepare for your upcoming session, please
                          take a moment to answer a few quick questions
                        </p>
                        <a
                          href={`${baseurl}${redirect}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="link-button-wrapper"
                        >
                          <button className="link-button">Answer now</button>
                        </a>
                      </>
                    ) : (
                      <>
                        <p className="countdown-text">
                          Please click the link below to join your upcoming
                          session and get started right on time!
                        </p>
                        <a
                          href={`${baseurl}${redirect}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="link-button-wrapper"
                        >
                          <button className="link-button">
                            Check upcoming session
                          </button>
                        </a>
                      </>
                    )
                  ) : (
                    <>
                      <p className="countdown-text">Start watching now:</p>
                      <a
                        href={`${baseurl}/my-learning`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="link-button-wrapper"
                      >
                        <button className="link-button">Watch Now</button>
                      </a>
                    </>
                  )}
                </>
              ) : (
                <></>
              )}
            </>
          )}

          {status === "success" ? (
            <>
              {" "}
              <div className="course-card">
                <div className="myburgo-download-heading-container">
                  {whatsappLink != null ? (
                    <>
                      <h3>Download the myburgo app to access your workshop</h3>
                    </>
                  ) : (
                    <>
                      {bookingsPage ? (
                        <>
                          <h3>
                            Download the myburgo app to book your remaining
                            sessions
                          </h3>
                        </>
                      ) : (
                        <>
                          <h3>Download the myburgo app to watch the videos</h3>
                        </>
                      )}
                    </>
                  )}

                  <div className="app-store-buttons">
                    <img
                      className="app-store-button"
                      src={playstore}
                      alt="Google Play Store"
                      onClick={() =>
                        window.open(
                          "https://play.google.com/store/apps/details?id=com.my.burgo&pcampaignid=web_share",
                          "_blank"
                        )
                      }
                    />

                    <img
                      className="app-store-button"
                      src={appstore}
                      alt="Apple App Store"
                      onClick={() =>
                        window.open(
                          "https://apps.apple.com/in/app/myburgo/id6736937593",
                          "_blank"
                        )
                      }
                    />
                  </div>
                </div>

                <div className="phone-mockup-container">
                  <img
                    className="phone-mockup"
                    src={phoneMockup}
                    alt="Phone Mockup"
                  />
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
      </DialogContent>
    </MuiDialog>
  );
};

export default ConfirmationDialog;
