import React from "react";
import SessionFeedbackCard from "../../Components/Feedback/SessionFeedbackCard";
import "./WorkshopFeedbackPage.css";
import { Helmet } from "react-helmet";

const SessionFeedbackPage = () => {
  return (
    <>
      <Helmet>
        <title>MyBurgo - Parenting App | Pregnancy App | SelfCare App</title>
        <meta
          name="description"
          content="MyBurgo is India's comprehensive parenting app offering expert guidance, interactive workshops, personalized resources, and community support for parents and pregnant women. Empower your parenting journey with child development tools, curated recipes, mental health support, and more."
        />
      </Helmet>
      <div className="workshop-feedback-page">
        <div className="workshop-feedback-container">
          <SessionFeedbackCard />
        </div>
      </div>
    </>
  );
};

export default SessionFeedbackPage;
