import React from "react";
import "./MyLearningPage.css";
import MyLearning from "../../Components/Mylearning/MyLearning";
import SliderBase from "../../Components/Slider/SliderBase";
import DownloadeApp from "../../Components/DownloadeApp/DownloadeApp";
import AppPromotion from "../../Components/Card/AppPromotion";
import { Helmet } from "react-helmet";

const MyLearningPage = () => {
  return (
    <>
      <Helmet>
        <title>MyBurgo - Parenting App | Pregnancy App | SelfCare App</title>
        <meta
          name="description"
          content="MyBurgo is India's comprehensive parenting app offering expert guidance, interactive workshops, personalized resources, and community support for parents and pregnant women. Empower your parenting journey with child development tools, curated recipes, mental health support, and more."
        />
      </Helmet>
      <section className={"sliderContainer"}>
        <SliderBase page={""} />
      </section>
      <section className={"myLearningContainer"}>
        <h2>My Learning</h2>
        <MyLearning />
      </section>
      <AppPromotion />
      {/* <section style={{ marginTop: "5%" }}>
        <DownloadeApp />
      </section> */}
    </>
  );
};

export default MyLearningPage;
