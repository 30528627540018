import React from "react";
import { Dialog, DialogContent, Typography } from "@mui/material";
import ConfettiExplosion from "react-confetti-explosion";
import "./confettiStyles.css";
import { DotLottieReact } from "@lottiefiles/dotlottie-react";
import couponSuccess from "../../Assets/lottie/couponSuccess.json";
import Lottie from "react-lottie-player";

const ConfettiExplosionComponent = ({ open, onClose, message, coupon }) => {
  return (
    <>
      {open && (
        <>
          {/* Confetti Explosion */}
          <div className="confetti-container">
            <ConfettiExplosion
              zIndex={2000}
              force={0.8}
              particleSize={10}
              particleCount={300}
              duration={3000}
            />
          </div>

          {/* Popup Message */}
          <Dialog
            open={open}
            onClose={onClose}
            sx={{
              "& .MuiDialog-paper": {
                zIndex: 2100,
                background: "#fff",
                borderRadius: "10px",
                padding: "20px",
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              },
            }}
          >
            <DialogContent sx={{ textAlign: "center" }}>
              {/* Top Center: Coupon Applied */}
              <Lottie
                loop={true}
                play
                animationData={couponSuccess}
                style={{ height: 150, marginBottom: "16px" }}
              />
              <Typography
                variant="body2"
                sx={{
                  color: "#9e9e9e", // Light grey
                  marginBottom: "8px",
                  fontSize: "20px",
                }}
              >
                '{coupon}' applied
              </Typography>

              {/* Middle Center: Bold Message */}
              <Typography
                variant="h4"
                sx={{
                  fontWeight: "bold",
                  fontSize: "30px", // Larger text
                  marginBottom: "16px",
                }}
              >
                {message}
              </Typography>

              {/* Bottom Center: Clickable Text */}
              <Typography
                variant="body1"
                onClick={onClose}
                sx={{
                  cursor: "pointer",
                  color: "#7a53ff", // Purple
                  fontWeight: "bold",
                  fontSize: "1rem",
                }}
              >
                Wow, Thanks!
              </Typography>
            </DialogContent>
          </Dialog>
        </>
      )}
    </>
  );
};

export default ConfettiExplosionComponent;
