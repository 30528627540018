import React, { useState, useEffect } from "react";
import styles from "./DateAvailability.module.css";
import morningIcon from "../../Assets/Logo_icons/morning.svg";
import middayIcon from "../../Assets/Logo_icons/afternoon.svg";
import eveningIcon from "../../Assets/Logo_icons/night.svg";

const TimeAvailability = ({
  groupedTimeSlots,
  selectedTimeSlot,
  setSelectedTimeSlot,
  setSelectedSlot,
  setSelectedDuration,
}) => {
  const [activePeriod, setActivePeriod] = useState(null);
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  useEffect(() => {
    // Check if groupedTimeSlots is valid and activePeriod is not set
    if (
      groupedTimeSlots &&
      Object.keys(groupedTimeSlots).length > 0 &&
      !activePeriod
    ) {
      const availablePeriods = ["Morning", "Midday", "Evening"];
      for (const period of availablePeriods) {
        if (groupedTimeSlots[period]?.length > 0) {
          setActivePeriod(period); // Set the active period
          break;
        }
      }
    }
  }, [groupedTimeSlots, activePeriod]);

  // Function to handle time slot selection
  const handleTimeSlotClick = (slot) => {
    if (slot.available) {
      setSelectedTimeSlot(slot.id);
      setSelectedDuration(slot.duration);
      setSelectedSlot(slot);
    }
  };

  const convertDurationToMinutes = (duration) => {
    const [hours, minutes, seconds] = duration.split(":").map(Number);
    return hours * 60 + minutes + Math.round(seconds / 60);
  };

  // Format time to "11:15 AM" format
  const formatTime = (time) => {
    const [hours, minutes] = time.split(":").map(Number);
    const period = hours >= 12 ? "PM" : "AM";
    const adjustedHours = hours % 12 || 12;
    return `${adjustedHours}:${minutes.toString().padStart(2, "0")} ${period}`;
  };

  const formatDuration = (duration) => {
    const [hours, minutes] = duration.split(":").map(Number);

    if (hours > 0) {
      return `${hours * 60 + minutes} mins`;
    }
    return `${minutes} mins`;
  };

  return (
    <div className={styles.container}>
      <h3>At what time should we meet?</h3>
      <div className={styles.timePeriodContainer}>
        {["Morning", "Midday", "Evening"].map((period) => (
          <button
            key={period}
            className={`${styles.timePeriodButton} ${
              activePeriod === period ? styles.active : ""
            }`}
            onClick={() => setActivePeriod(period)}
          >
            {period === "Morning" && (
              <div className={styles.dateTimeText}>
                <img src={morningIcon} alt="Morning" className={styles.icon} />
                Morning
              </div>
            )}
            {period === "Midday" && (
              <div className={styles.dateTimeText}>
                <img src={middayIcon} alt="Midday" className={styles.icon} />
                Midday
              </div>
            )}
            {period === "Evening" && (
              <div className={styles.dateTimeText}>
                <img src={eveningIcon} alt="Evening" className={styles.icon} />
                Evening
              </div>
            )}
          </button>
        ))}
        <div
          className={styles.slider}
          style={{
            transform: `translateX(${
              activePeriod === "Morning"
                ? "0%"
                : activePeriod === "Midday"
                  ? "100%"
                  : "200%"
            })`,
          }}
        />
      </div>

      <div className={styles.timeSlots}>
        {groupedTimeSlots[activePeriod]?.length > 0 ? (
          groupedTimeSlots[activePeriod].map((slot) => (
            <div
              key={slot.id}
              className={`${styles.timeSlot} ${
                slot.available ? styles.available : styles.unavailable
              } ${selectedTimeSlot === slot.id ? styles.selected : ""}`}
              onClick={() => handleTimeSlotClick(slot)}
            >
              {formatTime(slot.time)}
              <span className={styles.duration}>
                {formatDuration(slot.duration)}
              </span>
            </div>
          ))
        ) : (
          <div className={styles.noSlotsMessageContainer}>
            <div className={styles.noSlotsMessage}>No slots available</div>
            <div className={styles.noSlotsMessageSubtitle}>
              we can help you connecting with this expert, leave a message on
              whatsapp at +91 7710048212
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TimeAvailability;
