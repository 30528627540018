// src/components/Dialog/PaymentDialog.jsx
import React, { useState, useEffect, useRef } from "react";
import {
  Dialog as MuiDialog,
  DialogContent,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import {
  DialogContainer,
  LeftSection,
  RightSection,
  Card,
  Button,
  OtpContainer,
  ServiceCard,
  AmountCard,
  OtpInputContainer,
  ResendOtpLink,
  SuccessMessage,
  Label,
  InputField,
  PayButton,
  ErrorText,
  CouponContainer,
  CouponInput,
  ApplyButton,
  Select,
} from "./PaymentDialogStyles";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@mui/material/styles";
import {
  open_api,
  open_api_without_auth,
  open_api_with_user_token,
  isProduction,
  getCountryCode,
} from "../../Utils/network";
import ConfirmationDialog from "./ConfirmationDialog";
import LoadingOverlay from "./LoadingOverlay";
import checkIcon from "../../Assets/dialog/checkIcon.svg";
import failureIcon from "../../Assets/dialog/failureIcon.svg";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import axios from "axios";
import CongratulationsBanner from "../SuccessCard/CongratulationsBanner";
import CustomAlert from "../AlertDialog/CustomAlert";
import { useNavigate, useLocation } from "react-router-dom";
import GoogleSignIn from "../Login/GoogleSignIn";
import successIcon from "../../Assets/feedback/successIcon.svg";
import FailedIcon from "../../Assets/feedback/failedIcon.svg";
import {
  fetchCountryCode,
  validatePhoneNumber,
  validateEmail,
  handleOTPChange,
  handleKeyDown,
  handlePhoneNumberChange,
  handleEmailChange,
  applyCoupon,
  requestOTP,
  validateOTP,
  addAlert,
  removeAlert,
  fetchProfileTypes,
  handleCouponChange,
  checkUserInfoExists,
  fetchUserDetails,
  postExpertUserPurchase,
  validateGoogleLoginWithToken,
} from "./helper";
import useAuth from "../Hook/useAuth";
import { GoogleLogin } from "@react-oauth/google";
import LoginCard from "../Login/LoginCard";
import ConfettiExplosionComponent from "../Coupon/ConfettiExplosionComponent";
import ConfettiExplosion from "react-confetti-explosion";

const PaymentDialog = ({
  open,
  onClose,
  image,
  title,
  expertIds,
  description,
  sellingPrice,
  mrpPrice,
  workshop_id,
  date,
  time,
  isLive,
  whatsapplink,
}) => {
  const [useEmail, setUseEmail] = useState(false);
  const [usePhone, setUsePhone] = useState(false);
  const [contactValue, setContactValue] = useState("");
  const [isVerified, setVerified] = useState(false);
  const [otpRequested, setOtpRequested] = useState(false);
  const [otp, setOtp] = useState(Array(4).fill(""));
  const [otpValidated, setOtpValidated] = useState(false);
  const [firstName, setfirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [isConfirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [isPaymentSuccessful, setPaymentSuccessful] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [dialogProps, setDialogProps] = useState({});
  const [firstNameError, setfirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [phoneNumberError, setPhoneNumberError] = useState(false);
  const [countryCode, setCountryCode] = useState("in");
  const inputRefs = useRef([]);
  const [coupon, setCoupon] = useState("");
  const [discountAmount, setDiscountAmount] = useState(mrpPrice - sellingPrice);
  const [couponDiscount, setCouponDiscount] = useState(0);
  const [couponFound, setCouponFound] = useState(false);
  const [alerts, setAlerts] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [newSellingPrice, setNewSellingPrice] = useState(0);
  const [matchingCoupon, setMatchingCoupon] = useState({});
  const [profileType, setProfileType] = useState("");
  const [profileTypeError, setProfileTypeError] = useState(false);
  const {
    isAuthenticated,
    userName,
    userId,
    bearerToken,
    setIsAuthenticated,
    setUserId,
    setBearerToken,
    setUserName,
  } = useAuth();
  const [isUserInfoExists, setIsUserInfoExists] = useState(false);
  const [expertDetailsList, setExpertDetailsList] = useState([]);
  const [message, setMessage] = useState("");
  const [enablePayButton, setEnablePayButton] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false); // For showing confetti
  const [popupCoupon, setPopupCoupon] = useState(""); // For popup message

  useEffect(() => {}, [isAuthenticated]);
  useEffect(() => {}, [email]);
  useEffect(() => {
    updateUserDetails();
  }, [isAuthenticated, bearerToken, userId, userName]);

  useEffect(() => {
    const fetchCountry = async () => {
      const countryCode = await getCountryCode();
      setCountryCode(countryCode);
    };
    fetchCountry();
  }, []);

  useEffect(() => {
    if (expertIds && expertIds.length > 0) {
      fetchExpertDetails(expertIds);
    }
  }, [expertIds]);

  const updateUserDetails = async () => {
    if (isAuthenticated && bearerToken && userId) {
      // Ensure all necessary data is available
      const sessionUseEmail = localStorage.getItem("useEmail");
      const sessionUsePhone = localStorage.getItem("usePhone");
      setUseEmail(sessionUseEmail === "true");
      setUsePhone(sessionUsePhone === "true");
      setBearerToken(bearerToken);
      setUserName(userName);
      setUserId(userId);
      await fetchAndUpdateUserData(sessionUseEmail === "true");
    }
  };

  const fetchAndUpdateUserData = async (sessionUseEmail) => {
    if (!bearerToken || !userId) return; // Avoid unnecessary calls

    console.log("Fetching and updating user data...");
    const userInfo = await fetchUserDetails(bearerToken, userId);

    if (userInfo) {
      setPhoneNumber(userInfo?.phone);
      setEmail(userInfo?.email);
      setfirstName(userInfo?.first_name);
      setLastName(userInfo?.last_name);
      const isUserInfoComplete = checkUserInfoExists(
        userInfo?.first_name,
        userInfo?.last_name,
        userInfo?.email,
        userInfo?.phone,
        sessionUseEmail
      );
      setIsUserInfoExists(isUserInfoComplete);
      if (isUserInfoComplete) {
        setEnablePayButton(true);
      }
    }
  };

  const fetchExpertDetails = async (expertIds) => {
    try {
      const expertDetailsPromises = expertIds.map((id) =>
        open_api.get(`/profiles/expert/${id}`)
      );

      const expertsResponse = await Promise.all(expertDetailsPromises);
      const expertsData = await Promise.all(
        expertsResponse.map(async (res) => {
          return res.data;
        })
      );

      setExpertDetailsList(expertsData);
      //console.log("Fetched expert details with packages: ", expertsData);
    } catch (error) {
      console.error("Error fetching expert details:", error);
    }
  };

  const priceSymbol = countryCode === "in" ? "₹" : "$";
  const isUSD = countryCode === "in" ? "INR" : "USD";

  const handleApplyCoupon = async () => {
    var couponToApply = coupon;
    const result = await applyCoupon(
      coupon,
      workshop_id,
      null,
      bearerToken,
      sellingPrice,
      mrpPrice,
      setShowAlert,
      handleAddAlert,
      setCouponFound,
      setMatchingCoupon,
      setNewSellingPrice,
      setDiscountAmount,
      setCouponDiscount,
      setCoupon,
      isUSD
    );

    if (result.success) {
      setCouponFound(true);
      setShowConfetti(true);
      setPopupCoupon(couponToApply);
      setTimeout(() => setShowConfetti(false), 5000); // Hide confetti after 5 seconds
    }
  };

  const applyCouponCode = async (workShopDetails) => {
    //console.log("couponcodeFound", couponFound);
    const localData = {
      workshop_id: workshop_id ?? 0,
      ...(couponFound && { coupon_code_id: matchingCoupon.id ?? 0 }),
    };
    try {
      const res = await open_api_with_user_token(bearerToken).patch(
        `/workshop/session/card/${workShopDetails.id}/`,
        localData
      );
      if (res?.status === 200 || res?.status === 201 || res?.status === 204) {
        const userDetails = res?.data?.user_detail;
        setPhoneNumber(userDetails?.phone);
        return res?.data || [];
      }
    } catch (error) {
      //console.log("failed to get coupon list");
    }
  };

  const handleAddAlert = (title, message, type, autoClose = false) => {
    addAlert(title, message, type, autoClose, setAlerts);
  };

  const handleRemoveAlert = (id) => {
    removeAlert(id, setAlerts);
  };

  const handleClose = (event, reason) => {
    if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
      setUseEmail(false);
      setUsePhone(false);
      setOtpRequested(false);
      setContactValue("");
      setPaymentSuccessful(false);
      setVerified(false);
      setPhoneNumberError(false);
      setEmailError(false);
      setfirstNameError(false);
      setLastNameError(false);
      onClose();
      setOtp(Array(4).fill(""));
      setCouponFound(false);
      setCoupon([]);
      setOtpValidated(false);
      setCountryCode("in");
      setProfileType("");
      setProfileTypeError(false);
      setMessage("");
      if (!isAuthenticated) {
        setEmail("");
        setPhoneNumber("");
        setfirstName("");
        setLastName("");
        setBearerToken("");
        setUserId(0);
      }
      window.location.reload();
    }
  };

  const getWorkshopPayData = async () => {
    const workShopDetails = await getWorkshopCartDetails();
    const workshopPurchaseId = workShopDetails.id ?? 0;
    const appliedCoupon = await applyCouponCode(workShopDetails);
    const localData = {
      workshop_purchase_id: workshopPurchaseId,
      amount: appliedCoupon.total_amt,
    };
    try {
      const res = await open_api_with_user_token(bearerToken).post(
        `/payment/pay/`,
        localData
      );
      if (res?.status === 200 || res?.status === 201 || res?.status === 204) {
        return { paymentData: res?.data, appliedCoupon, workshopPurchaseId };
      }
    } catch (err) {
      //console.log(err);
    }
  };

  const getWorkshopCartDetails = async () => {
    const localData = {
      workshop_id: workshop_id ?? 0,
      currency: isUSD,
      purchasedFrom: 1,
    };

    try {
      const res = await open_api_with_user_token(bearerToken).post(
        `/workshop/session/card/`,
        localData
      );
      //console.log(res);
      if (res?.status === 200 || res?.status === 201 || res?.status === 204) {
        return res?.data;
      }
    } catch (err) {
      //console.log(err);
    }
  };

  const handleValidateOTP = async () => {
    setUseEmail(false);
    setUsePhone(true);
    await validateOTP(
      otp,
      phoneNumber,
      setOtpValidated,
      setVerified,
      setUserId,
      setBearerToken,
      setShowAlert,
      handleAddAlert,
      setfirstName,
      setLastName,
      setEmail,
      setUserName,
      setPhoneNumber
    );
  };

  const initiateRazorpayPayment = async (
    paymentData,
    appliedCoupon,
    workshopPurchaseId
  ) => {
    description = `Payment for ${title}`;
    setMessage("Confirming your order, please don't close the screen...");

    const razorpayKey = isProduction
      ? "rzp_live_0MAqWVbokRW0Uu"
      : "rzp_test_41tt7Y8uKXr9rl";
    const options = {
      key: razorpayKey,
      amount: paymentData.amount * 100, // Razorpay works with paise, so multiply by 100
      currency: isUSD ? "USD" : "INR",
      name: "myburgo",
      description: description,
      order_id: paymentData.order_id,
      timeout: 600,
      handler: (response) => {
        handlePaymentSuccess(paymentData.id, workshopPurchaseId);
      },
      prefill: {
        name: `${firstName} ${lastName}`,
        email: appliedCoupon.user_detail.email,
        contact: appliedCoupon.user_detail.phone,
      },
      notes: {
        address: "website",
      },
      theme: {
        color: "#7A53FF",
      },
      modal: {
        ondismiss: function () {
          handlePaymentFailure(
            { error: "Payment window closed without completing the payment." },
            paymentData.id
          );
        },
      },
    };
    const rzp = new window.Razorpay(options);

    rzp.on("payment.failed", ({ response }) => {
      console.error("Payment failed:", response.error);
    });

    rzp.open();
  };

  const fetchWorkshopDetailsAndPay = async () => {
    setIsLoading(true);
    try {
      if (isUserInfoExists) {
        const { paymentData, appliedCoupon, workshopPurchaseId } =
          await getWorkshopPayData();
        await initiateRazorpayPayment(
          paymentData,
          appliedCoupon,
          workshopPurchaseId
        );
      } else {
        setIsLoading(false);
        setShowAlert(true);
        handleAddAlert(
          "Payment Error",
          "There was an error while processing your payment",
          "error",
          true
        );
      }
    } catch (error) {
      console.error("Error in flow:", error);
    }
  };

  const handlePaymentSuccess = async (cart_id, workshopPurchaseId) => {
    try {
      const localData = {
        is_bypassed: false,
      };
      await new Promise((resolve) => setTimeout(resolve, 2000));
      const res = await open_api_with_user_token(bearerToken).patch(
        `/payment/pay/${cart_id}/`,
        localData
      );
      if (res?.status === 200 || res?.status === 201 || res?.status === 204) {
        const sessionData = sessionStorage.getItem("expertSession");
        if (sessionData) {
          const expertSessionData = JSON.parse(sessionData);
          postExpertUserPurchase(
            expertSessionData,
            bearerToken,
            workshopPurchaseId,
            null
          );
        }
        setPaymentSuccessful(true);
        setIsLoading(false);
        setDialogProps({
          status: "success",
          statusText: "Thank you",
          statusDescription: `Your purchase of the workshop ${title} is confirmed! 🎉`,
          statusIcon: successIcon,
          backgroundColor: "#FFFF",
        });
        setConfirmationDialogOpen(true);
      }
    } catch (err) {
      //console.log(err);
    } finally {
      setIsLoading(false); // End loading
    }
  };

  const handlePaymentFailure = async (response, cart_id) => {
    try {
      const res = await open_api_with_user_token(bearerToken).patch(
        `/payment/pay/${cart_id}/`
      );
      if (res?.status === 200 || res?.status === 201 || res?.status === 204) {
        console.error("Payment failed:", response.error);
        setPaymentSuccessful(true);
        setDialogProps({
          status: "failure",
          statusText: "Payment Failed",
          statusDescription:
            "There was an issue with the payment. Please try again or contact support at hello@myburgo.com if the problem persists.",
          statusIcon: FailedIcon,
          backgroundColor: "#FFFF",
        });
        setIsLoading(false);
        setConfirmationDialogOpen(true);
      }
    } catch (err) {
      //console.log(err);
    } finally {
      setIsLoading(false); // End loading
    }
  };

  const handleCloseConfirmationDialog = (event, reason) => {
    if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
      setConfirmationDialogOpen(false);
      resetAllStates();
    }
  };

  const resetAllStates = () => {
    setConfirmationDialogOpen(false);
    setUseEmail(false);
    setUsePhone(false);
    setOtpRequested(false);
    setContactValue("");
    setPaymentSuccessful(false);
    setVerified(false);
    setIsLoading(false);
    setPhoneNumberError(false);
    setEmailError(false);
    setfirstNameError(false);
    setLastNameError(false);
    onClose();
    setOtp(Array(4).fill(""));
    setCouponFound(false);
    setCoupon([]);
    setOtpValidated(false);
    setCountryCode("in");
    setProfileType("");
    setProfileTypeError(false);
    setMessage("");
    if (!isAuthenticated) {
      setEmail("");
      setPhoneNumber("");
      setfirstName("");
      setLastName("");
      setBearerToken("");
      setUserId(0);
    }
    window.location.reload();
  };

  const handlePayEnable = (enable) => {
    setEnablePayButton(enable);
  };

  const handleUserInfoExists = (exists) => {
    setIsUserInfoExists(exists);
  };

  const handleBearerToken = (token) => {
    setBearerToken(token);
  };

  return (
    <>
      <MuiDialog
        open={open && !isPaymentSuccessful}
        onClose={handleClose}
        maxWidth="md"
        fullWidth={true}
        fullScreen={fullScreen}
      >
        {isLoading && <LoadingOverlay message={message} />}
        <DialogContent
          sx={{ padding: 0, position: "relative", background: "#e7e7e7" }}
        >
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContainer>
            <LeftSection>
              <img src={image} alt="Dialog" />
              <h1>{title}</h1>
              <h2>
                {expertDetailsList.length > 0 && (
                  <>
                    by{" "}
                    {expertDetailsList
                      .slice(0, expertDetailsList.length - 1)
                      .map((expert, index) => (
                        <span key={index}>
                          {expert.user?.first_name} {expert.user?.last_name}
                          {index < expertDetailsList.length - 2 ? ", " : ""}
                        </span>
                      ))}
                    {expertDetailsList.length > 1 && (
                      <>
                        {" and "}
                        {
                          expertDetailsList[expertDetailsList.length - 1].user
                            ?.first_name
                        }{" "}
                        {
                          expertDetailsList[expertDetailsList.length - 1].user
                            ?.last_name
                        }
                      </>
                    )}
                  </>
                )}
              </h2>
              {isLive ? <p>{description}</p> : <p>{description}</p>}
            </LeftSection>
            <RightSection>
              <h1>Payment details</h1>
              <h2>Complete your purchase by providing your payment details.</h2>
              <LoginCard
                setEnablePayButton={handlePayEnable}
                bypass={true}
                updateIsUserInfoExists={handleUserInfoExists}
                updateBearerToken={handleBearerToken}
              />

              {otpValidated || isAuthenticated ? (
                <Card>
                  <h3>Have a coupon code? Enter here</h3>
                  <CouponContainer>
                    <div className="couponHandler">
                      <CouponInput
                        type="text"
                        placeholder="Enter Coupon Code"
                        value={coupon}
                        onChange={(event) =>
                          handleCouponChange(event, setCoupon)
                        }
                      />
                      <ApplyButton onClick={handleApplyCoupon}>
                        Apply
                      </ApplyButton>
                    </div>
                  </CouponContainer>
                </Card>
              ) : (
                <></>
              )}
              <ServiceCard>
                <div className="service-details">
                  <div className="service-info">
                    <h4>Service</h4>
                    <p>{title}</p>
                    {couponFound && <p>Coupon discount</p>}
                  </div>
                  <div className="service-price">
                    <div style={{ flexDirection: "column" }}>
                      <div
                        style={{
                          paddingBottom: couponFound ? "10px" : "0px",
                        }}
                      >
                        <span className="original-price">
                          {priceSymbol}
                          {mrpPrice}
                        </span>
                        <span className="discounted-price">
                          {priceSymbol}
                          {sellingPrice}
                        </span>
                      </div>
                      {couponFound && (
                        <span
                          className="discounted-price"
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          - {priceSymbol}
                          {couponDiscount}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="divider"></div>
                <AmountCard>
                  <div className="amount-left">
                    <h4>Amount to be paid</h4>
                  </div>
                  <div className="amount-right">
                    <span className="discounted-price">
                      {priceSymbol}
                      {couponFound ? newSellingPrice : sellingPrice}
                    </span>
                  </div>
                </AmountCard>
                {(discountAmount > 0 || couponFound) && (
                  <CongratulationsBanner
                    priceSymbol={priceSymbol}
                    discount={discountAmount}
                  />
                )}
              </ServiceCard>

              {isAuthenticated ? (
                <PayButton
                  className="payButton"
                  onClick={fetchWorkshopDetailsAndPay}
                  disabled={!enablePayButton}
                >
                  Proceed to Pay
                </PayButton>
              ) : (
                <></>
              )}
            </RightSection>
            {showAlert &&
              alerts.map((alert) => (
                <CustomAlert
                  key={alert.id}
                  title={alert.title}
                  message={alert.message}
                  onClose={() => handleRemoveAlert(alert.id)}
                  autoClose={alert.autoClose}
                  type={alert.type}
                />
              ))}
          </DialogContainer>
        </DialogContent>
      </MuiDialog>

      <ConfettiExplosionComponent
        open={showConfetti}
        onClose={() => setShowConfetti(false)}
        message={`you saved ${priceSymbol}${couponDiscount} with this coupon`}
        coupon={popupCoupon}
      />

      {isConfirmationDialogOpen && (
        <ConfirmationDialog
          open={isConfirmationDialogOpen}
          onClose={handleCloseConfirmationDialog}
          image={image}
          title={title}
          description={description}
          {...dialogProps}
          redirect={"/my-learning"}
          countdownDuration={8}
          whatsappLink={whatsapplink}
        />
      )}
    </>
  );
};

export default PaymentDialog;
