import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Dialog as MuiDialog, DialogContent, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  LeftContainer,
  Header,
  ExpertInfo,
  ExpertImage,
  ExpertDetails,
  ExpertName,
  ExpertDescription,
  PackageHeader,
  PackageContainer,
  PackageCard,
  PackageTitle,
  PackagePrice,
  DialogContainer,
  InnerCardContainer,
  InnerCard,
  ChangeLink,
  InnerCardFlex,
} from "../Dialog/BookSessionDialogStyles";
import {
  RightSection,
  Card,
  Button,
  OtpContainer,
  ServiceCard,
  AmountCard,
  OtpInputContainer,
  ResendOtpLink,
  SuccessMessage,
  Label,
  InputField,
  ErrorText,
  CouponContainer,
  CouponInput,
  ApplyButton,
  PayButton,
  NoPackagesContainer,
  NoPackagesImage,
  NoPackagesText,
  Select,
} from "../Dialog/PaymentDialogStyles";
import {
  fetchCountryCode,
  validatePhoneNumber,
  validateEmail,
  handleOTPChange,
  handleKeyDown,
  handlePhoneNumberChange,
  handleEmailChange,
  applyCoupon,
  requestOTP,
  validateOTP,
  addAlert,
  removeAlert,
  fetchProfileTypes,
  handleCouponChange,
  checkUserInfoExists,
  fetchUserDetails,
  postExpertUserPurchase,
  validateGoogleLoginWithToken,
} from "../Dialog/helper";
import { duration, useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import useAuth from "../Hook/useAuth";
import emptyBox from "../../Assets/Logo_icons/emptybox.svg";
import DateAvailability from "../Dialog/DateAvailability";
import CustomAlert from "../AlertDialog/CustomAlert";
import CongratulationsBanner from "../SuccessCard/CongratulationsBanner";
import LoginCard from "../Login/LoginCard";
import {
  open_api,
  open_api_without_auth,
  open_api_with_user_token,
  isProduction,
  getCountryCode,
} from "../../Utils/network";
import ConfirmationDialog from "../Dialog/ConfirmationDialog";
import successIcon from "../../Assets/feedback/successIcon.svg";
import FailedIcon from "../../Assets/feedback/failedIcon.svg";
import LoadingOverlay from "../Dialog/LoadingOverlay";
import CoachingCard from "../Card/CoachingCard";
import ConfettiExplosionComponent from "../Coupon/ConfettiExplosionComponent";

const BookingSessionPayment = ({
  expertDetails,
  bypassPurchase,
  purchasedUserId,
  bookedPackageId,
  purchaseId,
  bypassPackage,
  selectedSlotSession,
  selectedPackageSession,
  onBypassPurchaseChange,
}) => {
  const [enablePayButton, setEnablePayButton] = useState(false);
  const {
    isAuthenticated,
    userName,
    userId,
    bearerToken,
    setIsAuthenticated,
    setUserId,
    setBearerToken,
    setUserName,
  } = useAuth();
  const [useEmail, setUseEmail] = useState(false);
  const [usePhone, setUsePhone] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState(false);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [firstName, setfirstName] = useState("");
  const [lastName, setlastName] = useState("");
  const [firstNameError, setfirstNameError] = useState(false);
  const [lastNameError, setlastNameError] = useState(false);
  const [isUserInfoExists, setIsUserInfoExists] = useState(false);
  const [isConfirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [selectedSlot, setSelectedSlot] = useState(selectedSlotSession);
  const [selectedPackage, setSelectedPackage] = useState(
    bookedPackageId || selectedPackageSession.id
  );
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [countryCode, setCountryCode] = useState("in");
  const [sessionCartId, setSessionCartId] = useState(0);
  const [selectedSessionPackage, setSelectedSessionPackage] = useState(
    selectedPackageSession
  );
  const [couponFound, setCouponFound] = useState(false);
  const [matchingCoupon, setMatchingCoupon] = useState({});
  const [isUserDataRequired, setIsUserDataRequired] = useState(false);
  const [isPaymentSuccessful, setPaymentSuccessful] = useState(false);
  const [dialogProps, setDialogProps] = useState({});
  const [otpRequested, setOtpRequested] = useState(false);
  const [contactValue, setContactValue] = useState("");
  const [isVerified, setVerified] = useState(false);
  const [otp, setOtp] = useState(Array(4).fill(""));
  const [otpValidated, setOtpValidated] = useState(false);
  const [coupon, setCoupon] = useState("");
  const [discountAmount, setDiscountAmount] = useState(0);
  const [couponDiscount, setCouponDiscount] = useState(0);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState(
    selectedSlotSession.time
  );
  const [selectedDate, setSelectedDate] = useState(selectedSlotSession.date);
  const [showPaymentSection, setShowPaymentSection] = useState(false);
  const [showDateAvialabiltySection, setShowDateAvialbilitySection] =
    useState(false);
  const [changePackage, setChangePackage] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [profileTypes, setProfileTypes] = useState([]);
  const [profileType, setProfileType] = useState("");
  const [profileTypeId, setProfileTypeId] = useState(0);
  const [profileTypeError, setProfileTypeError] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [alerts, setAlerts] = useState([]);
  const [newSessionPackagePrice, setNewSessionPackagePrice] = useState(0);
  const [selectedDuration, setSelectedDuration] = useState(
    selectedSlotSession.duration
  );
  const [showConfetti, setShowConfetti] = useState(false); // For showing confetti
  const [popupCoupon, setPopupCoupon] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    updateUserDetails();
  }, [isAuthenticated, bearerToken, userId, userName]);

  useEffect(() => {
    const fetchCountry = async () => {
      const countryCode = await getCountryCode();
      setCountryCode(countryCode);
    };
    fetchCountry();
  }, []);

  const updateUserDetails = async () => {
    if (isAuthenticated && bearerToken && userId) {
      // Ensure all necessary data is available
      const sessionUseEmail = localStorage.getItem("useEmail");
      const sessionUsePhone = localStorage.getItem("usePhone");
      setUseEmail(sessionUseEmail === "true");
      setUsePhone(sessionUsePhone === "true");
      setBearerToken(bearerToken);
      setUserName(userName);
      setUserId(userId);
      await fetchAndUpdateUserData(sessionUseEmail === "true");
    }
  };

  const fetchAndUpdateUserData = async (sessionUseEmail) => {
    const userInfo = await fetchUserDetails(bearerToken, userId);
    setPhoneNumber(userInfo?.phone);
    setEmail(userInfo?.email);
    setfirstName(userInfo?.first_name);
    setlastName(userInfo?.last_name);
    const isUserInfoComplete = checkUserInfoExists(
      userInfo?.first_name,
      userInfo?.last_name,
      userInfo?.email,
      userInfo?.phone,
      sessionUseEmail
    );
    setIsUserInfoExists(isUserInfoComplete);
    if (isUserInfoComplete) {
      setEnablePayButton(true);
    }
  };
  const handlePayEnable = (enable) => {
    setEnablePayButton(enable);
  };

  const handleUserInfoExists = (exists) => {
    setIsUserInfoExists(exists);
  };

  const handleBearerToken = (token) => {
    setBearerToken(token);
  };

  const handleSelectedSlotChange = (selectedSlot) => {
    setSelectedSlot(selectedSlot);
  };

  const priceSymbol = countryCode === "in" ? "₹" : "$";
  const isUSD = countryCode === "in" ? "INR" : "USD";

  const applyCouponCode = async (sessionCartDetails) => {
    const localData = {
      package_id: selectedSessionPackage.id ?? 0,
      ...(couponFound && { coupon_code_id: matchingCoupon.id ?? 0 }),
    };
    try {
      const res = await open_api_with_user_token(bearerToken).patch(
        `/session/card/${sessionCartDetails.id}/`,
        localData
      );
      if (res?.status === 200 || res?.status === 201 || res?.status === 204) {
        return res?.data || [];
      }
    } catch (error) {}
  };

  const getSessionCartDetails = async () => {
    const localData = {
      package_id: selectedPackage ?? 0,
      currency: isUSD,
      purchasedFrom: 1,
    };

    try {
      const res = await open_api_with_user_token(bearerToken).post(
        `/session/card/`,
        localData
      );
      //console.log(res);
      if (res?.status === 200 || res?.status === 201 || res?.status === 204) {
        setSessionCartId(res?.data?.id);
        return res?.data;
      }
    } catch (err) {
      //console.log(err);
    }
  };

  const getSessionPayData = async () => {
    const sessionCartDetails = await getSessionCartDetails();
    const appliedCoupon = await applyCouponCode(sessionCartDetails);
    const localData = {
      session_purchase_id: sessionCartDetails.id ?? 0,
      amount: appliedCoupon.total_amt,
    };
    try {
      const res = await open_api_with_user_token(bearerToken).post(
        `/payment/pay/`,
        localData
      );
      if (res?.status === 200 || res?.status === 201 || res?.status === 204) {
        return {
          ...res.data,
          sessionCartDetailsId: sessionCartDetails.id,
          appliedCoupon: appliedCoupon,
        };
      }
    } catch (err) {
      //console.log(err);
    }
  };

  const postBookedSlot = async (sessionCartDetailsId) => {
    const slotId = selectedSlot.id ?? 0;
    // console.log("userId = ", userLocalId);
    // console.log("slotid = ", slotId);
    // console.log("packageId = ", selectedPackage);
    // console.log("workshopcharid =", sessionCartDetailsId);
    const localData = {
      purchase_id: sessionCartDetailsId,
      user_id: bypassPurchase ? purchasedUserId : userId,
      slot_id: slotId,
      package_id: selectedPackage,
      status: 1,
    };

    try {
      const res = await open_api_with_user_token(bearerToken).post(
        `/session/booked_slot/`,
        localData
      );
      if (res?.status === 200 || res?.status === 201 || res?.status === 204) {
        return true;
      } else {
        return false;
      }
    } catch (err) {
      //console.log(err);
    }
    return false;
  };

  const fetchWorkshopDetailsAndPay = async () => {
    setIsLoading(true);
    if (bypassPurchase) {
      setMessage("Confirming your order, please don't close the screen...");
      handleBookedPaymentSuccess();
    } else {
      try {
        if (isUserInfoExists) {
          const payData = await getSessionPayData();
          const { appliedCoupon, sessionCartDetailsId, ...resData } = payData;
          if (payData.amount > 0) {
            await initiateRazorpayPayment(
              resData,
              sessionCartDetailsId,
              appliedCoupon
            );
          } else {
            setMessage(
              "Confirming your order, please don't close the screen..."
            );
            handlePaymentSuccess(payData.id, sessionCartDetailsId, true);
          }
        }
      } catch (error) {
        // Handle error appropriately
      }
    }
  };

  const fetchQuestionsData = async () => {
    try {
      const res = await open_api_with_user_token(bearerToken).get(
        `/session/experts/${expertDetails?.slug}/questions/${selectedPackage}`
      );
      if (res?.status === 200 || res?.status === 201 || res?.status === 204) {
        //console.log(res?.data);
        if (res?.data.results.length === 0) {
          setIsUserDataRequired(false);
        } else {
          setIsUserDataRequired(true);
        }
      }
    } catch (error) {}
    return false;
  };

  const initiateRazorpayPayment = async (
    payData,
    sessionCartDetailsId,
    appliedCoupon
  ) => {
    const chart_id = payData.id;
    setMessage("Confirming your order, please don't close the screen...");
    const razorpayKey = isProduction
      ? "rzp_live_0MAqWVbokRW0Uu"
      : "rzp_test_41tt7Y8uKXr9rl";
    const options = {
      key: razorpayKey,
      amount: payData.amount * 100, // Razorpay works with paise, so multiply by 100
      currency: isUSD ? "USD" : "INR",
      name: "myburgo",
      description: `Payment for expert session with ${expertDetails?.user?.first_name} ${expertDetails?.user?.last_name}`,
      order_id: payData.order_id,
      timeout: 600,
      handler: (response) => {
        //console.log("Payment successful:", response);
        handlePaymentSuccess(payData.id, sessionCartDetailsId, false);
      },
      prefill: {
        name: `${appliedCoupon.user_detail.first_name} ${appliedCoupon.user_detail.last_name}`,
        email: appliedCoupon.user_detail.email,
        contact: appliedCoupon.user_detail.phone,
      },
      notes: {
        address: "website",
      },
      theme: {
        color: "#7A53FF",
      },
      modal: {
        ondismiss: function () {
          //console.log("Checkout form closed");
          handlePaymentFailure(
            { error: "Payment window closed without completing the payment." },
            payData.id
          );
        },
      },
    };
    const rzp = new window.Razorpay(options);

    rzp.on("payment.failed", ({ response }) => {
      console.error("Payment failed:", response.error);
    });

    rzp.open();
  };

  const handlePaymentSuccess = async (
    cart_id,
    sessionCartDetailsId,
    is_bypassed
  ) => {
    try {
      const localData = {
        is_bypassed: is_bypassed,
      };
      await new Promise((resolve) => setTimeout(resolve, 2000));
      const res = await open_api_with_user_token(bearerToken).patch(
        `/payment/pay/${cart_id}/`,
        localData
      );
      //console.log(res);
      if (res?.status === 200 || res?.status === 201 || res?.status === 204) {
        const sessionData = sessionStorage.getItem("expertSession");
        if (sessionData) {
          const expertSessionData = JSON.parse(sessionData);
          postExpertUserPurchase(
            expertSessionData,
            bearerToken,
            null,
            sessionCartDetailsId
          );
        }
        postBookedSlot(sessionCartDetailsId);
        fetchQuestionsData();
        setPaymentSuccessful(true);
        setIsLoading(false);
        setDialogProps({
          status: "success",
          statusText: "Thank you",
          statusDescription: `Your purchase of the expert session with ${expertDetails?.user?.first_name} is confirmed! 🎉`,
          statusIcon: successIcon,
          backgroundColor: "#FFFF",
          bookingsPage: "sessions",
        });
        setConfirmationDialogOpen(true);
      }
    } catch (err) {
      //console.log(err);
    } finally {
      setIsLoading(false); // End loading
    }
  };

  const handleBookedPaymentSuccess = async () => {
    try {
      await new Promise((resolve) => setTimeout(resolve, 2000));
      const sessionData = sessionStorage.getItem("expertSession");
      if (sessionData) {
        const expertSessionData = JSON.parse(sessionData);
        postExpertUserPurchase(
          expertSessionData,
          bearerToken,
          null,
          purchaseId
        );
      }
      const bookingSuccessFull = await postBookedSlot(purchaseId);
      //console.log("booking status ", bookingSuccessFull);
      fetchQuestionsData();
      if (bookingSuccessFull) {
        setPaymentSuccessful(true);
        setIsLoading(false);
        setDialogProps({
          status: "success",
          statusText: "Thank you",
          statusDescription:
            "You have successfully booked an expert Session. We will be sending you an email confirmation shortly.",
          statusIcon: successIcon,
          backgroundColor: "#FFFF",
          bookingsPage: "sessions",
        });
        setConfirmationDialogOpen(true);
        onBypassPurchaseChange(false);
      } else {
        setPaymentSuccessful(false);
        setDialogProps({
          status: "failure",
          statusText: "Booking Failed",
          statusDescription:
            "There was an issue while booking your slot. Please try again or contact support at hello@myburgo.com if the problem persists.",
          statusIcon: FailedIcon,
          backgroundColor: "#FFFF",
        });
        setIsLoading(false);
        setConfirmationDialogOpen(true);
      }
    } catch (err) {
      //console.log(err);
    } finally {
      setIsLoading(false); // End loading
    }
  };

  const handlePaymentFailure = async (response, cart_id) => {
    try {
      const res = await open_api_with_user_token(bearerToken).patch(
        `/payment/pay/${cart_id}/`
      );
      console.error("Payment failed:", response);
      if (res?.status === 200 || res?.status === 201 || res?.status === 204) {
        setPaymentSuccessful(true);
        setDialogProps({
          status: "failure",
          statusText: "Payment Failed",
          statusDescription:
            "There was an issue with the payment. Please try again or contact support at hello@myburgo.com if the problem persists.",
          statusIcon: FailedIcon,
          backgroundColor: "#FFFF",
        });
        setIsLoading(false);
        setConfirmationDialogOpen(true);
      }
    } catch (err) {
      //console.log(err);
    } finally {
      setIsLoading(false); // End loading
    }
  };

  const handleAddAlert = (title, message, type, autoClose = false) => {
    addAlert(title, message, type, autoClose, setAlerts);
  };

  const handleRemoveAlert = (id) => {
    removeAlert(id, setAlerts);
  };

  const handleApplyCoupon = async () => {
    var sellingPrice = selectedSessionPackage.selling_price;
    var mrpPrice = selectedSessionPackage.mrp;
    if (countryCode != "in") {
      sellingPrice = selectedSessionPackage.selling_price_usd;
      mrpPrice = selectedSessionPackage.mrp_usd;
    }
    var couponToApply = coupon;
    const result = await applyCoupon(
      coupon,
      null,
      selectedSessionPackage.id,
      bearerToken,
      sellingPrice,
      mrpPrice,
      setShowAlert,
      handleAddAlert,
      setCouponFound,
      setMatchingCoupon,
      setNewSessionPackagePrice,
      setDiscountAmount,
      setCouponDiscount,
      setCoupon
    );

    if (result.success) {
      setCouponFound(true);
      setShowConfetti(true);
      setPopupCoupon(couponToApply);
      setTimeout(() => setShowConfetti(false), 5000); // Hide confetti after 5 seconds
    }
  };

  const formatTime = (time) => {
    const [hours, minutes, seconds] = time.split(":");
    const date = new Date();
    date.setHours(hours, minutes, seconds);
    return date.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
  };

  const formatDuration = (duration) => {
    const [hours, minutes] = duration.split(":").map(Number);

    if (hours > 0) {
      return `${hours * 60 + minutes} mins`;
    }
    return `${minutes} mins`;
  };

  const formatDate = (date) => {
    const options = { day: "2-digit", month: "short", year: "numeric" };
    return date.toLocaleDateString("en-GB", options);
  };

  const handleChangePackage = () => {
    navigate(`/bookings/${expertDetails.slug}`);
  };

  const handleChangeSlot = () => {
    window.location.reload();
    // if (!bypassPurchase) {
    //   navigate(
    //     `/bookings/${expertDetails.slug}/details?packageId=${selectedPackage}`
    //   );
    // } else {
    //   navigate(
    //     `/bookings/${expertDetails.slug}/details?packageId=${selectedPackage}`,
    //     {
    //       state: {
    //         bypassPurchase: true,
    //         bypassPackage: true,
    //         bookedPackageId: selectedPackage,
    //         purchaseId: bookedPackageId,
    //         purchasedUserId: purchasedUserId,
    //         duration: selectedDuration,
    //         isDataRequired: isUserDataRequired,
    //       },
    //     }
    //   );
    // }
  };

  const handleCloseConfirmationDialog = (event, reason) => {
    if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
      setConfirmationDialogOpen(false);
      resetAllStates();
    }
  };

  const getSessionDisplayText = (sessionsCount) => {
    if (sessionsCount === 1) {
      return "1 Session"; // Single session
    } else if (sessionsCount >= 5 && sessionsCount <= 9) {
      return `${sessionsCount - 1} Sessions + 1 Free Session`; // Between 5 and 9
    } else if (sessionsCount >= 10) {
      return `${sessionsCount - 2} Sessions + 2 Free Sessions`; // 10 or more sessions
    } else {
      return `${sessionsCount} Sessions`; // For counts between 2 and 4
    }
  };

  const resetAllStates = () => {
    window.location.reload();
  };

  return (
    <>
      <MuiDialog
        open={!isPaymentSuccessful}
        onClose={handleCloseConfirmationDialog}
        maxWidth="md"
        fullWidth={true}
        fullScreen={fullScreen}
        PaperProps={{
          sx: {
            width: "auto",
            maxWidth: "none",
          },
        }}
      >
        {isLoading && <LoadingOverlay message={message} />}
        <DialogContent
          sx={{ padding: 0, position: "relative", background: "#e7e7e7" }}
        >
          <IconButton
            aria-label="close"
            onClick={handleCloseConfirmationDialog}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContainer>
            <RightSection>
              <h1>Payment details</h1>
              <h2>Complete your purchase by providing your payment details.</h2>
              <LoginCard
                setEnablePayButton={handlePayEnable}
                bypass={"true"}
                updateIsUserInfoExists={handleUserInfoExists}
                updateBearerToken={handleBearerToken}
              />

              {otpValidated || isAuthenticated ? (
                !bypassPurchase ? (
                  <Card>
                    <h3>Have a coupon code? Enter here</h3>
                    <CouponContainer>
                      <div className="couponHandler">
                        <CouponInput
                          type="text"
                          placeholder="Enter Coupon Code"
                          value={coupon}
                          onChange={(event) =>
                            handleCouponChange(event, setCoupon)
                          }
                        />
                        <ApplyButton onClick={handleApplyCoupon}>
                          Apply
                        </ApplyButton>
                      </div>
                    </CouponContainer>
                  </Card>
                ) : (
                  <></>
                )
              ) : (
                <></>
              )}
              <Card>
                <h3>
                  {!bypassPurchase
                    ? "You have selected the below package"
                    : "You have selected the below slot"}
                </h3>
                <InnerCardContainer>
                  {!bypassPackage ? (
                    <InnerCardFlex>
                      <InnerCard>
                        <p style={{ fontWeight: 700 }}>
                          {selectedSessionPackage.title}
                        </p>
                        <p style={{ fontWeight: 600, fontSize: "14px" }}>
                          {getSessionDisplayText(
                            selectedSessionPackage.sessions_count
                          )}
                        </p>
                        <p style={{ fontWeight: 500 }}>
                          {priceSymbol}
                          {countryCode === "in"
                            ? selectedSessionPackage.selling_price
                            : selectedSessionPackage.selling_price_usd}
                        </p>
                      </InnerCard>
                      <ChangeLink onClick={handleChangePackage}>
                        Change Package
                      </ChangeLink>
                    </InnerCardFlex>
                  ) : (
                    <></>
                  )}

                  <InnerCardFlex>
                    <InnerCard>
                      <p style={{ fontWeight: 700 }}>
                        {formatTime(selectedTimeSlot)}
                      </p>
                      <p style={{ fontWeight: 600 }}>
                        ({formatDuration(selectedDuration)})
                      </p>
                      <p style={{ fontWeight: 500 }}>
                        {formatDate(new Date(selectedDate))}
                      </p>
                    </InnerCard>
                    <ChangeLink onClick={handleChangeSlot}>
                      Change Slot
                    </ChangeLink>
                  </InnerCardFlex>
                </InnerCardContainer>
              </Card>
              {!bypassPurchase ? (
                <ServiceCard>
                  <div className="service-details">
                    <div className="service-info">
                      <h4>Service</h4>
                      <p>{selectedSessionPackage.title}</p>
                      {couponFound && <p>Coupon discount</p>}
                    </div>
                    <div className="service-price">
                      <div style={{ flexDirection: "column" }}>
                        <div
                          style={{
                            paddingBottom: couponFound ? "10px" : "0",
                          }}
                        >
                          <span className="original-price">
                            {priceSymbol}
                            {countryCode === "in"
                              ? selectedSessionPackage.mrp
                              : selectedSessionPackage.mrp_usd}
                          </span>
                          <span className="discounted-price">
                            {priceSymbol}
                            {countryCode === "in"
                              ? selectedSessionPackage.selling_price
                              : selectedSessionPackage.selling_price_usd}
                          </span>
                        </div>
                        {couponFound && (
                          <span
                            className="discounted-price"
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            - {priceSymbol}
                            {couponDiscount}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="divider"></div>
                  <AmountCard>
                    <div className="amount-left">
                      <h4>Amount to be paid</h4>
                    </div>
                    <div className="amount-right">
                      <span className="discounted-price">
                        {priceSymbol}
                        {couponFound
                          ? newSessionPackagePrice
                          : countryCode === "in"
                            ? selectedSessionPackage.selling_price
                            : selectedSessionPackage.selling_price_usd}
                      </span>
                    </div>
                  </AmountCard>
                  <CongratulationsBanner
                    priceSymbol={priceSymbol}
                    discount={
                      couponFound
                        ? discountAmount
                        : countryCode === "in"
                          ? selectedSessionPackage.mrp -
                            selectedSessionPackage.selling_price
                          : selectedSessionPackage.mrp_usd -
                            selectedSessionPackage.selling_price_usd
                    }
                  />
                </ServiceCard>
              ) : (
                <></>
              )}

              {isAuthenticated ? (
                <PayButton
                  className="payButton"
                  onClick={fetchWorkshopDetailsAndPay}
                  disabled={!enablePayButton}
                >
                  {!bypassPurchase ? "Proceed to Pay" : "Confirm booking"}
                </PayButton>
              ) : (
                <></>
              )}
            </RightSection>
            {showAlert &&
              alerts.map((alert) => (
                <CustomAlert
                  key={alert.id}
                  title={alert.title}
                  message={alert.message}
                  onClose={() => handleRemoveAlert(alert.id)}
                  autoClose={alert.autoClose}
                  type={alert.type}
                />
              ))}
          </DialogContainer>
        </DialogContent>
      </MuiDialog>

      <ConfettiExplosionComponent
        open={showConfetti}
        onClose={() => setShowConfetti(false)}
        message={`you saved ${priceSymbol}${couponDiscount} with this coupon`}
        coupon={popupCoupon}
      />
      {isConfirmationDialogOpen && (
        <ConfirmationDialog
          open={isConfirmationDialogOpen}
          onClose={handleCloseConfirmationDialog}
          image={expertDetails?.image}
          title={expertDetails?.user?.first_name}
          description={expertDetails?.about}
          {...dialogProps}
          redirect={
            dialogProps.status === "success" &&
            isUserDataRequired &&
            fetchQuestionsData()
              ? `/expert/${expertDetails?.slug}/bookings/${selectedSlot.id}/session-data/${selectedPackage}`
              : `/profile/sessions/upcoming`
          }
          countdownDuration={5}
        />
      )}
    </>
  );
};

export default BookingSessionPayment;
