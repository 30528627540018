import React, { useEffect, useState } from "react";
import BookingSession from "../../Components/BookingSession/BookingSession";
import { useParams } from "react-router-dom";
import { open_api, getCountryCode } from "../../Utils/network";
import { useLocation } from "react-router-dom";
import LoadingOverlay from "../../Components/Dialog/LoadingOverlay";
import FAQ from "../../Components/Faq/FAQ";
import styles from "./BookingSessionPage.module.css";
import BookingExpertDetails from "../../Components/BookingSession/BookingExpertDetails";
import BookingExpertAbout from "../../Components/BookingSession/BookingExpertAbout";
import CoachingCard from "../../Components/Card/CoachingCard";
import PackageInfo from "../../Components/BookingSession/PackageInfo";
import DateAvailability from "../../Components/Dialog/DateAvailability";
import { addAlert, removeAlert } from "../../Components/Dialog/helper";
import CustomAlert from "../../Components/AlertDialog/CustomAlert";
import { useSearchParams, useNavigate } from "react-router-dom";
import BookingSessionPayment from "../../Components/BookingSession/BookingSessionPayment";
import AppPromotion from "../../Components/Card/AppPromotion";
import { Helmet } from "react-helmet";

const BookingSessionDetailsPage = () => {
  const [showPaymentSection, setShowPaymentSection] = useState(false);
  const [expertDetails, setExpertsDetails] = useState(null);
  const [packageDetails, setPackageDetails] = useState([]); // Set initial state to null to track loading state
  const [loading, setLoading] = useState(true); // Loading state to track data fetching
  const { expertslug } = useParams();
  const location = useLocation();
  const [countryCode, setCountryCode] = useState("in");
  const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedDuration, setSelectedDuration] = useState(null);
  const [selectedPackageDuration, setSelectionPackageDuration] = useState(null);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const [alerts, setAlerts] = useState([]);
  const [searchParams] = useSearchParams();
  const packageId = searchParams.get("packageId");
  const navigate = useNavigate();

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleTimeSlotChange = (timeSlot) => {
    setSelectedTimeSlot(timeSlot);
  };

  const handleDurationChange = (duration) => {
    setSelectedDuration(duration);
  };

  const handleSelectedSlotChange = (selectedSlot) => {
    setSelectedSlot(selectedSlot);
  };

  const handleProceedToPayment = () => {
    if (!selectedTimeSlot || !selectedDate) {
      if (!selectedDate) {
        setShowAlert(true);
        handleAddAlert(
          "Input Error",
          "Please select a date before proceeding.",
          "error",
          true
        );
        return;
      } else {
        setShowAlert(true);
        handleAddAlert(
          "Input Error",
          "Please select a time before proceeding.",
          "error",
          true
        );
        return;
      }
    }
    setShowPaymentSection(true);
  };

  useEffect(() => {
    const fetchCountry = async () => {
      const countryCode = await getCountryCode();
      setCountryCode(countryCode);
    };
    fetchCountry();
  }, []);

  // Destructure location.state with default values
  const {
    bypassPurchase = false,
    bypassPackage = false,
    bookedPackageId = null,
    purchaseId = null,
    purchasedUserId = null,
    duration = null,
    isDataRequired = false,
    remainingSession = null,
  } = location.state || {};

  const [bypassPurchaseState, setBypassPurchaseState] =
    useState(bypassPurchase);

  const handleBypassPurchaseChange = (value) => {
    setBypassPurchaseState(value);
  };

  //console.log(location.state);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  // Fetch expert details and packages
  useEffect(() => {
    const getExpertDetails = async () => {
      try {
        const res = await open_api.get(`profiles/experts/${expertslug}/`);
        if (res?.status === 200 || res?.status === 201 || res?.status === 204) {
          setExpertsDetails(res?.data);

          // Fetch packages after expert details are available
          const packageRes = await open_api.get(
            `/session/packages/${packageId}/details`
          );
          if (
            packageRes?.status === 200 ||
            packageRes?.status === 201 ||
            packageRes?.status === 204
          ) {
            setPackageDetails(packageRes?.data?.data);
          } else {
            navigate("/bookings/packageNotfound/404");
          }
        }
      } catch (error) {
        console.error("Error fetching expert details:", error);
      } finally {
        setLoading(false); // Stop loading when both expert details and packages are fetched
      }
    };

    getExpertDetails();
  }, [expertslug]);

  const faqs = [
    {
      id: 1,
      title: "How can I join the meeting once booked?",
      answer: [
        {
          id: 1,
          answer:
            "Once you have booked, you can download the MyBurgo app and access the meeting link under 'My Learning,' or you can find the link on the website in your profile under 'Upcoming Sessions.' We will also send you an email with the link to join the meeting, along with a reminder email 60 minutes before the actual call. For support, contact us at hello@myburgo.com or call +91 7710048212.",
        },
      ],
    },
    {
      id: 2,
      title: "Can I cancel a booking",
      answer: [
        {
          id: 1,
          answer:
            "No, you cannot cacel a booking, For support contact hello@myburgo.com or give a call to +91 7710048212",
        },
      ],
    },
    {
      id: 3,
      title: "Can I reschedule a booking",
      answer: [
        {
          id: 1,
          answer:
            "Currently we dont support rescheduling a booking. however if you wish to reschedule a booking you can reach out to hello@myburgo.com or +91 7710048212.",
        },
      ],
    },
    // Add more FAQ items here
  ];

  const priceSymbol = countryCode === "in" ? "₹" : "$";

  const handleAddAlert = (title, message, type, autoClose = false) => {
    addAlert(title, message, type, autoClose, setAlerts);
  };

  const handleRemoveAlert = (id) => {
    removeAlert(id, setAlerts);
  };

  // Render loading spinner or message if data is being fetched
  if (loading) {
    return <LoadingOverlay />;
  }
  return (
    <div>
      <Helmet>
        <title>MyBurgo - Parenting App | Pregnancy App | SelfCare App</title>
        <meta
          name="description"
          content="MyBurgo is India's comprehensive parenting app offering expert guidance, interactive workshops, personalized resources, and community support for parents and pregnant women. Empower your parenting journey with child development tools, curated recipes, mental health support, and more."
        />
      </Helmet>

      <div className={styles.bookingSessionPage}>
        <BookingExpertDetails expert={expertDetails} />
        <div className={styles.card}>
          <div className={styles.contentContainer}>
            <div className={styles.leftSection} data-section="package-info">
              <PackageInfo
                title={packageDetails.title}
                sessions={packageDetails.sessions_count}
                duration={packageDetails.slot_duration.duration}
                type="Video meeting"
                priceSymbol={priceSymbol}
                price={
                  countryCode === "in"
                    ? packageDetails.selling_price
                    : packageDetails.selling_price_usd
                }
                originalPrice={
                  countryCode === "in"
                    ? packageDetails.mrp
                    : packageDetails.mrp_usd
                }
                discount={true}
              />
              <div className={styles.leftSectionAbout}>
                <BookingExpertAbout
                  about={expertDetails}
                  isPackage={true}
                  aboutPackage={packageDetails.about_package}
                />
              </div>
            </div>
            <div
              className={`${styles.divider} ${styles.horizontal}`}
              data-section="divider-horizontal-1"
            ></div>
            <div className={`${styles.divider} ${styles.vertical}`}></div>
            <div
              className={styles.rightSection}
              data-section="date-availability"
            >
              <DateAvailability
                expertId={expertDetails.id}
                onDateChange={handleDateChange}
                onTimeSlotChange={handleTimeSlotChange}
                onProceedToPayment={handleProceedToPayment}
                onSelectedSlot={handleSelectedSlotChange}
                onDurationChange={handleDurationChange}
                packageDuration={packageDetails.slot_duration.duration}
                bypassPurchase={bypassPurchase}
              />
            </div>
            <div
              className={`${styles.divider} ${styles.horizontal}`}
              data-section="divider-horizontal-2"
            ></div>
            <div className={styles.leftSection} data-section="expert-about">
              <BookingExpertAbout
                about={expertDetails}
                isPackage={true}
                aboutPackage={packageDetails.about_package}
              />
            </div>
            {showPaymentSection && (
              <BookingSessionPayment
                expertDetails={expertDetails}
                bypassPurchase={bypassPurchaseState}
                purchasedUserId={purchasedUserId}
                bookedPackageId={bookedPackageId}
                purchaseId={purchaseId}
                bypassPackage={bypassPackage}
                selectedSlotSession={selectedSlot}
                selectedPackageSession={packageDetails}
                onBypassPurchaseChange={handleBypassPurchaseChange}
              />
            )}
          </div>
          <div className={styles.sectionContainer}>
            <h2 className={styles.CRHeading}>Frequently asked questions</h2>
            <FAQ data={faqs} />
          </div>
          <AppPromotion />
          {showAlert &&
            alerts.map((alert) => (
              <CustomAlert
                key={alert.id}
                title={alert.title}
                message={alert.message}
                onClose={() => handleRemoveAlert(alert.id)}
                autoClose={alert.autoClose}
                type={alert.type}
              />
            ))}
        </div>
      </div>
    </div>
  );
};

export default BookingSessionDetailsPage;
