import React from "react";
import "./SessionDataPage.css";
import SessionDataCard from "../../Components/Feedback/SessionDataCard";
import { open_api } from "../../Utils/network";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";

const SessionDataPage = () => {
  const [questions, setQuestions] = useState([]);
  const { expertSlug, bookingId, packageID } = useParams();

  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        const response = await open_api.get(
          `/session/experts/${expertSlug}/questions/${packageID}`
        );
        //console.log(response.data.results);
        setQuestions(response.data.results);
      } catch (error) {
        console.error("Failed to fetch questions:", error);
      }
    };

    fetchQuestions();
  }, [expertSlug, packageID, bookingId]);

  return (
    <div>
      <Helmet>
        <title>MyBurgo - Parenting App | Pregnancy App | SelfCare App</title>
        <meta
          name="description"
          content="MyBurgo is India's comprehensive parenting app offering expert guidance, interactive workshops, personalized resources, and community support for parents and pregnant women. Empower your parenting journey with child development tools, curated recipes, mental health support, and more."
        />
      </Helmet>
      <div className="session-page">
        <div className="session-container">
          <SessionDataCard questions={questions} bookingId={bookingId} />
        </div>
      </div>
    </div>
  );
};

export default SessionDataPage;
