import React from "react";
import "./PageNotFound.css";
//import pageNotFoundImage from "../../Assets/404/pagenotfound.png";
import pageNotFoundImage from "../../Assets/404/404_not_found.png";
import { Helmet } from "react-helmet";

const PageNotFound = () => {
  return (
    <>
      <Helmet>
        <title>MyBurgo - Parenting App | Pregnancy App | SelfCare App</title>
        <meta
          name="description"
          content="MyBurgo is India's comprehensive parenting app offering expert guidance, interactive workshops, personalized resources, and community support for parents and pregnant women. Empower your parenting journey with child development tools, curated recipes, mental health support, and more."
        />
      </Helmet>
      <div className="not-found-container">
        <div className="not-found-text">
          <h1>404</h1>
          <h2>Page not found</h2>
          <p>
            The page you are looking for is either broken or has been removed
          </p>
        </div>
        <div className="not-found-image">
          <img src={pageNotFoundImage} alt="Page not found" />
        </div>
      </div>
    </>
  );
};

export default PageNotFound;
