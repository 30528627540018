import React, { useEffect, useState } from "react";
import "./ProfilePage.css";
import Orders from "../../Components/Profile/Order";
import Account from "../../Components/Profile/Account";
import UpcomingSession from "../../Components/Profile/Session/UpcomingSession";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { fetchUserDetails } from "../../Components/Dialog/helper";
import useAuth from "../../Components/Hook/useAuth";
import { isProduction } from "../../Utils/network";
import { Helmet } from "react-helmet";

function ProfilePage() {
  const [activeSection, setActiveSection] = useState("Account");
  const [sessionExpanded, setSessionExpanded] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [isExpert, setIsExpert] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const { userName } = useAuth();

  useEffect(() => {
    const storedUserId = localStorage.getItem("userId");
    const bearerToken = localStorage.getItem("bearerToken");
    const fetchUserData = async () => {
      try {
        const userInfo = await fetchUserDetails(bearerToken, storedUserId);
        const isExpert = userInfo.user_type_str === "Expert";
        setIsExpert(isExpert);
      } catch (error) {
        console.error("Failed to fetch user data", error);
      }
    };

    fetchUserData();
  }, [userName, isExpert]);

  const handleNavigation = (section) => {
    switch (section) {
      case "Account":
        navigate("/profile/account");
        break;
      case "Orders":
        navigate("/profile/orders");
        break;
      case "Revenue":
        navigate("/profile/revenue");
        break;
      case "UpcomingSession":
        navigate("/profile/sessions/upcoming");
        break;
      case "remainingSession":
        navigate("/profile/orders", { state: { selectionType: "Session" } });
        break;
      default:
        navigate("/profile/account");
    }
  };

  const handleSessionClick = () => {
    if (sessionExpanded === true) {
      setSessionExpanded(false);
    } else {
      setSessionExpanded(true);
    }

    setActiveSection("UpcomingSession");
  };

  const handleLogout = () => {
    sessionStorage.clear();
    localStorage.removeItem("bearerToken");
    localStorage.removeItem("userName");
    localStorage.removeItem("userId");
    navigate("/");
    window.location.reload();
  };

  const handleAccountDelete = () => {
    navigate("/delete_account");
  };

  const handleVisitWebsite = () => {
    if (isProduction) {
      window.open("https://dashboard.myburgo.com", "_blank");
    } else {
      window.open("https://dashboard.dev.myburgo.com", "_blank");
    }
  };

  return (
    <>
      <Helmet>
        <title>MyBurgo - Parenting App | Pregnancy App | SelfCare App</title>
        <meta
          name="description"
          content="MyBurgo is India's comprehensive parenting app offering expert guidance, interactive workshops, personalized resources, and community support for parents and pregnant women. Empower your parenting journey with child development tools, curated recipes, mental health support, and more."
        />
      </Helmet>
      <div className="profile-page">
        {/* Left Side Navigation */}
        <div className="left-card">
          <div className="profile-photo">
            <img src="https://via.placeholder.com/100" alt="Profile" />
          </div>
          <div className="profile-name">{userName}</div>
          <div className="nav-options">
            <div
              className={`nav-option ${location.pathname === "/profile/account" ? "active" : ""}`}
              onClick={() => handleNavigation("Account")}
            >
              <i className="fas fa-user-circle"></i>
              <span>Account</span>
            </div>
            <div
              className={`nav-option ${location.pathname === "/profile/orders" ? "active" : ""}`}
              onClick={() => handleNavigation("Orders")}
            >
              <i className="fas fa-shopping-cart"></i>
              <span>Your orders</span>
            </div>
            {isExpert ? (
              <>
                <div
                  className={`nav-option ${location.pathname === "/profile/revenue" ? "active" : ""}`}
                  onClick={() => handleNavigation("Revenue")}
                >
                  <i className="fas fa-inr"></i>
                  <span>Revenue</span>
                </div>
              </>
            ) : (
              <></>
            )}

            <div className={`nav-option`} onClick={handleSessionClick}>
              <i className="fas fa-calendar-alt"></i>
              <span>Your Sessions</span>
              <i
                className={`fas fa-chevron-${sessionExpanded ? "up" : "down"} dropdown-icon`}
              ></i>
            </div>
            {sessionExpanded && (
              <>
                <div
                  className={`session-sub-option ${location.pathname === "/profile/sessions/upcoming" ? "active" : ""}`}
                  onClick={() => handleNavigation("UpcomingSession")}
                >
                  <span>Upcoming Session</span>
                </div>
                <div
                  className={`session-sub-option ${location.pathname === "/profile/sessions/remainingsession" ? "active" : ""}`}
                  onClick={() => handleNavigation("remainingSession")}
                >
                  <span>book remaining sessions</span>
                </div>
              </>
            )}
            <div className="nav-option" onClick={handleAccountDelete}>
              <i className="fas fa-trash"></i>
              <span>Delete Account</span>
            </div>
            <div className="nav-option logout-option" onClick={handleLogout}>
              <i className="fas fa-sign-out-alt"></i>
              <span>Logout</span>
            </div>
          </div>

          {isExpert ? (
            <div className="animated-button-container">
              <button
                className="animated-gradient-button"
                onClick={handleVisitWebsite}
              >
                Dashboard
              </button>
            </div>
          ) : (
            <></>
          )}
        </div>

        <div className="right-card">
          <Outlet />
        </div>
      </div>
    </>
  );
}

export default ProfilePage;
