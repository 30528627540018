import React, { useEffect, useState } from "react";
import styles from "./ChildCare.module.css";
import CustomButtons from "../../Components/CustomButtons/CustomButtons";
import cards from "../../Assets/cards.png";
import SpecialitiesCard from "../../Components/SpecialitiesCard/SpecialitiesCard";
import Offer from "../../Components/Offer/Offer";
import Recommended from "../../Components/Recommended/Recommended";
import ExpertService from "../../Components/ExpertService/ExpertService";
import ClientreviewCard from "../../Components/ClientreviewCard/ClientreviewCard";
import BlogsCard from "../../Components/BlogsCard/BlogsCard";
import AboutUs from "../../Components/AboutUs/AboutUs";
import ExpertDetailsWorkshop from "../../Components/ExpertDetailsWorkshop/ExpertDetailsWorkshop";
import BlogSlider from "../../Components/BlogSlider/BlogSlider";
import PricingCards from "../../Components/PricingCards/PricingCards";
import { open_api } from "../../Utils/network";
import ContactUsFrom from "../../Components/ContactUsFrom/ContactUsFrom";
import SliderBase from "../../Components/Slider/SliderBase";
import AppPromotion from "../../Components/Card/AppPromotion";
import { Helmet } from "react-helmet";

function ChildCare() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [preReview, setPreReview] = useState([]);

  useEffect(() => {
    homeReviewCard();
  }, []);
  const homeReviewCard = () => {
    open_api
      .get(`home/testimonials`)
      .then((res) => {
        //   console.log(res?.data?.results);
        setPreReview(res?.data?.results);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div>
      <Helmet>
        <title>MyBurgo - Parenting App | Pregnancy App | SelfCare App</title>
        <meta
          name="description"
          content="MyBurgo is India's comprehensive parenting app offering expert guidance, interactive workshops, personalized resources, and community support for parents and pregnant women. Empower your parenting journey with child development tools, curated recipes, mental health support, and more."
        />
      </Helmet>

      <div className={styles.container}>
        <div>
          <SliderBase page={"childCare"} />
        </div>
        <div className={styles.headers}>
          <h1 className={styles.title}>Childcare Categories</h1>
          <CustomButtons />
        </div>

        <section className={styles.cards}>
          <PricingCards />
        </section>
        {/*TODO: after launch */}
        {/* <section className={styles.offer}>
                <Offer />
            </section> */}
        {/* <section>
        <Recommended />
      </section> */}
        <section>
          <ExpertService />
        </section>

        <section className={styles.ourclient}>
          <div className={styles.clients}>
            <p>OUR CLIENTS</p>
            <h1>Testimonials</h1>
          </div>
          <ClientreviewCard data={preReview} />
        </section>
        <AppPromotion />
        <div className={styles.bgColor}>
          {/* <section className={styles.blogs}>
          <h1 className={styles.blogName}>BLOGS </h1>
          <BlogSlider />
        </section> */}
          <section>
            <AboutUs />
          </section>
        </div>
        <ContactUsFrom />
      </div>
    </div>
  );
}

export default ChildCare;
