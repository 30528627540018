import React, { useEffect, useState } from "react";
import styles from "./Blogs.module.css";
import LearnBabycare from "../../Assets/LearnBabycare.png";
import babyThrow from "../../Assets/babyThrow.png";
import BlogsCard from "../../Components/BlogsCard/BlogsCard";
import neha from "../../Assets/neha.png";
import clock from "../../Assets/clock.png";
import { Button } from "@mui/material";
import mobileBaby from "../../Assets/mobileBaby.png";
import mobileBlog from "../../Assets/mobileBlog.png";
import CustomButtons from "../../Components/CustomButtons/CustomButtons";
import { open_api } from "../../Utils/network";
import { useNavigate } from "react-router-dom";
import AckModal from "../../Components/AckModal/AckModal";
import SliderBase from "../../Components/Slider/SliderBase";

function Blogs() {
  const [blogs, setBlogs] = useState([]);

  const navigate = useNavigate();

  const handleBlogNav = (id) => {
    navigate(`/blogs/${id}`);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    getBlogDetails();
  }, []);

  const getBlogDetails = () => {
    open_api
      .get(
        `blog/blog_pages?fields=id%2Ctitle%2Cpublished_date%2Cintro%2Cheader_image_url%2Ctags%2Cread_time%2Cauthors&type=blogs.BlogPage`
      )
      .then((res) => {
        // console.log(res?.data?.items, "getBlogDetails");
        if (res?.status == 200 || res?.status == 201 || res?.status == 204) {
          setBlogs(res?.data?.items);
        }
      })
      .catch((err) => {
        //console.log(err);
      });
  };

  return (
    <>
      <div className={styles.desktopContainer}>
        <div>
          <SliderBase page={"blog"} />
        </div>
        <div className={styles.aboutBabies}>
          <div
            className={styles.left}
            onClick={() => handleBlogNav(blogs[blogs.length - 1]?.id)}
          >
            <img
              src={
                blogs[blogs.length - 1]?.header_image_url?.url
                  ? blogs[blogs.length - 1]?.header_image_url?.url
                  : babyThrow
              }
            ></img>

            <div className={styles.desc}>
              <h1>{blogs[blogs.length - 1]?.title}</h1>
              <div className={styles.details}>
                <div className={styles.img}>
                  <img
                    className={styles.circularImage}
                    src={blogs[blogs.length - 1]?.authors[0].image}
                  ></img>
                </div>
                <div>
                  <p>{blogs[blogs.length - 1]?.authors[0].name}</p>
                </div>
              </div>

              <div className={styles.date}>
                <p>{blogs[blogs.length - 1]?.published_date}</p>
              </div>

              <div className={styles.watched}>
                <div className={styles.btmRight}>
                  <img src={clock}></img>
                </div>
                <p>{blogs[blogs.length - 1]?.read_time} minutes read time</p>
              </div>
            </div>
          </div>
          <div className={styles.right}>
            {blogs?.slice(0, 2)?.map((item) => {
              return <BlogsCard data={item} />;
            })}
          </div>
        </div>
      </div>

      {/* <div className={styles.mobileContainer}>
        <div className={styles.aboutBabies}>
          <div
            className={styles.left}
            onClick={() => handleBlogNav(blogs[blogs.length - 1]?.id)}
          >
            <img
              src={
                blogs[blogs.length - 1]?.header_image_url?.url
                  ? blogs[blogs.length - 1]?.header_image_url?.url
                  : babyThrow
              }
            ></img>

            <div className={styles.desc}>
              <h1>{blogs[blogs.length - 1]?.title}</h1>

              <div className={styles.bottomTop}>
                <div>
                  <div className={styles.details}>
                    <div className={styles.img}>
                      <img
                        className={styles.circularImage}
                        src={blogs[blogs.length - 1]?.authors[0].image}
                      ></img>
                    </div>
                    <div>
                      <p>{blogs[blogs.length - 1]?.authors[0].name}</p>
                    </div>
                  </div>
                </div>

                <div className={styles.baseline}>
                  <div className={styles.date}>
                    <p>{blogs[blogs.length - 1]?.published_date}</p>
                  </div>

                  <div className={styles.watched}>
                    <div className={styles.btmRight}>
                      <img src={clock}></img>
                    </div>
                    <p>
                      {blogs[blogs.length - 1]?.read_time} minutes read time
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={styles.right}>
            {blogs?.slice(0, 2)?.map((item) => {
              return <BlogsCard data={item} />;
            })}
          </div>
        </div>
      </div> */}

      <div className={styles.btns}>
        <CustomButtons />
      </div>
      <div className={styles.cards}>
        {blogs
          ?.slice()
          ?.reverse()
          .map((item, index) => {
            return (
              <div key={index}>
                {" "}
                <BlogsCard data={item} />
              </div>
            );
          })}
      </div>
      {/* <AckModal/> */}
    </>
  );
}

export default Blogs;

const carddetails = [
  {
    id: 1,
    title: "Sibling Rivalry",
  },
  {
    id: 2,
    title: "Behaviour Analysis",
  },
  {
    id: 3,
    title: "Special Needs",
  },
  {
    id: 4,
    title: "Co-Parenting",
  },
  {
    id: 5,
    title: "Sibling Rivalry",
  },
  {
    id: 6,
    title: "Behaviour Analysis",
  },
  {
    id: 7,
    title: "Special Needs",
  },
  {
    id: 8,
    title: "Co-Parenting",
  },
  {
    id: 9,
    title: "Sibling Rivalry",
  },
  {
    id: 10,
    title: "Behaviour Analysis",
  },
  {
    id: 11,
    title: "Special Needs",
  },
  {
    id: 12,
    title: "Co-Parenting",
  },
];
const cards = [
  {
    id: 1,
  },
  {
    id: 2,
  },
  {
    id: 3,
  },
  {
    id: 4,
  },
  {
    id: 5,
  },
  {
    id: 6,
  },
  {
    id: 7,
  },
  {
    id: 8,
  },
  {
    id: 9,
  },
];
const blogCards = [
  {
    id: 1,
  },
  { id: 2 },
];
