import React, { useState, useEffect } from "react";
import SliderBase from "../../Components/Slider/SliderBase";
import styles from "./HomePage.module.css";
// import expertImg from '../../Assets/expertImg.png'
// import ExpertData from '../../Components/ExpertData/ExpertData'
import HomeTabs from "../../Components/HomeTabs/HomeTabs";
import HomeDetails from "../../Components/HomeDetails/HomeDetails";
// import bgHearts from '../../Assets/bgHearts.png'
// import btmsum from '../../Assets/btmsum.png'
// import btmstar from '../../Assets/btmstar.png'
import Services from "../../Components/Services/Services";
import AboutUs from "../../Components/AboutUs/AboutUs";
import ClientreviewCard from "../../Components/ClientreviewCard/ClientreviewCard";
import SpecialitiesCard from "../../Components/SpecialitiesCard/SpecialitiesCard";
import BlogsCard from "../../Components/BlogsCard/BlogsCard";
import ExpertWeHave from "../../Components/ExpertWeHave/ExpertWeHave";
import BlogSlider from "../../Components/BlogSlider/BlogSlider";
import LabTabs from "../../Components/Tabs/LabTabs";
import HomeReviwCard from "../../Components/HomeReviewCard/HomeReviwCard";
import CustomizedDialogs from "../../Components/PopUp/CustomizedDialogs";
import JoinUsForm from "../../Components/JoinUsForm/JoinUsForm";
import AppPromotion from "../../Components/Card/AppPromotion";
import { Helmet } from "react-helmet";
function HomePage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>MyBurgo - Parenting App | Pregnancy App | SelfCare App</title>
        <meta
          name="description"
          content="MyBurgo is India's comprehensive parenting app offering expert guidance, interactive workshops, personalized resources, and community support for parents and pregnant women. Empower your parenting journey with child development tools, curated recipes, mental health support, and more."
        />
      </Helmet>
      <div className={styles.container}>
        <div>
          <SliderBase page={"homepage"} />
        </div>
        <section>
          <ExpertWeHave />
        </section>
        <section>
          <LabTabs />
        </section>
        <section className={styles.Details}>
          <HomeDetails />
        </section>

        <AppPromotion />

        <div className={styles.bgColor}>
          <section className={styles.services}>
            <div className={styles.top}>
              <span>Services</span>
              <p className={styles.servicesh1}>Explore How Can We Help You</p>
            </div>
            <Services text={services} />
          </section>

          <section className={styles.ourclient}>
            <div className={styles.clients}>
              <p>OUR CLIENTS</p>
              <h1>Testimonials</h1>
            </div>
            <HomeReviwCard />
          </section>

          {/*TODO: to be fixed later*/}
          {/* <section className={styles.blogs}>
            <h1 className={styles.blogName}>BLOGS </h1>
            <BlogSlider />

          </section> */}

          <section>
            <AboutUs />
          </section>
          {/* <CustomizedDialogs/> */}
        </div>
        <div style={{ margin: "50px 0" }}>
          <JoinUsForm />
        </div>
      </div>
    </>
  );
}

export default HomePage;

const services = {
  btn: "Services",
  head: "Explore How Can We Help You",
};
