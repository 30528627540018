import React, { useEffect, useRef, useState } from "react";
import styles from "./index.module.css";
import { Button, colors, Skeleton } from "@mui/material";
import bgheart from "../../Assets/expert_page/bgHeart.svg";
import ReactPlayer from "react-player";
import video from "../../Assets/videos/videos.mp4";
import clock from "../../Assets/course_page/clock.svg";
import calender from "../../Assets/course_page/calender.svg";
import ClientreviewCard from "../../Components/ClientreviewCard/ClientreviewCard";
import ExpertBlogSlider from "../../Components/ExpertBlogslider/ExpertBlogSlider";
import JoinUsForm from "../../Components/JoinUsForm/JoinUsForm";
import plane from "../../Assets/course_page/aeroplane.svg";
import Banner from "../../Components/Banner/Banner";
import PaymentDialog from "../../Components/Dialog/PaymentDialog";
import {
  open_api,
  open_api_without_auth,
  getCountryCode,
} from "../../Utils/network";
import ExpertSection from "../../Components/ExpertSection/ExpertSection";
import { useParams } from "react-router-dom";
import { usePopup } from "../../Components/PopUp/PopupContext"; // Adjust the import path
import bannerClock from "../../Assets/course_page/bannerClock.svg";
import bannerCalendar from "../../Assets/course_page/bannerCalender.svg";
import { fetchCountryCode } from "../../Components/Dialog/helper";
import playicon from "../../Assets/course_page/play.svg";
import FAQ from "../../Components/Faq/FAQ";
import PageNotFound from "../PageNotFound/PageNotFound";
import VideoPlayer from "../../Components/VideoPlayer/VideoPlayer";
import AppPromotion from "../../Components/Card/AppPromotion";
import { Helmet } from "react-helmet";
import BreadcrumbSchema from "../../Components/Misc/BreadcrumbSchema";

const CourseDetailsPage = () => {
  const [workshopDetails, setWorkshopDetails] = useState({});
  const [whyThisWorks, setWhyThisWorks] = useState([]);
  const [whatYouLearn, setWhatYouLearn] = useState([]);
  const [workshopImage, setWorkshopImage] = useState([]);
  const [workshopTitle, setWorkshopTitle] = useState("");
  const [workshopDescription, setWorkshopDescription] = useState("");
  const [curriculumData, setCirriculumData] = useState([]);
  const [expertShowcase, setExpertShowCase] = useState([]);
  const playerRef = useRef(null);
  const { expert, workshopSlug } = useParams();
  const [expertDetails, setExpertDetails] = useState({});
  const half = Math.ceil(whatYouLearn.length / 2);
  const firstColumn = whatYouLearn.slice(0, half);
  const secondColumn = whatYouLearn.slice(half);
  const media = workshopImage.find((item) => item.category === 3);
  const [loading, setLoading] = useState(true); // Add loading state
  const [countryCode, setCountryCode] = useState("in");
  const [seats, setSeats] = useState(0);
  const [isUnlimitedSeats, setIsUnlimitedSeats] = useState(true);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [faq, setFaq] = useState([]);
  const [workshopExperts, setWorkshopExperts] = useState([]);
  const [workshopTestimonial, setWorkshopTestimonial] = useState([]);
  const [isActive, setIsActive] = useState(null);
  const [whatsapplink, setWhatsappLink] = useState(null);
  const [is_show_workshopcovers, setIsShowWorkshopCovers] = useState(true);
  const [metaTitle, setMetaTitle] = useState("");
  const [metaDescription, setMetaDescription] = useState("");
  const [ogTitle, setOgTitle] = useState("");
  const [ogDescription, setOgDescription] = useState("");
  const [ogImage, setOgImage] = useState("");

  useEffect(() => {
    const fetchCountry = async () => {
      const countryCode = await getCountryCode();
      setCountryCode(countryCode);
    };
    fetchCountry();
  }, []);

  useEffect(() => {
    getWorkshopDetails(workshopSlug);
  }, [workshopSlug]);

  useEffect(() => {
    if (expertDetails?.id) {
      getExpertShow(expertDetails.id);
    }
  }, [expertDetails]);

  const getExpertShow = (id) => {
    open_api_without_auth
      .get(`profiles/experts/showcase?expert=${id}`)
      .then((res) => {
        setExpertShowCase(res?.data?.results);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getWorkshopTestimonial(workshopSlug);
  }, [workshopSlug]);

  const getWorkshopTestimonial = (workshopSlug) => {
    open_api_without_auth
      .get(`/home/testimonials/workshop?slug=${workshopSlug}`)
      .then((res) => {
        setWorkshopTestimonial(res?.data?.results);
      })
      .catch((err) => console.log(err));
  };

  const getWorkshopDetails = async (workshopSlug) => {
    setLoading(true);
    try {
      const res = await open_api.get(`/home/workshop/detail/${workshopSlug}/`);
      if (res?.status === 200 || res?.status === 201 || res?.status === 204) {
        const data = res?.data?.data;
        setWorkshopDetails(data);
        setWhyThisWorks(data.why_this_works);
        setWhatYouLearn(data.what_you_learn);
        setWorkshopImage(data.work_shop_media);
        setWorkshopTitle(data.title);
        setWorkshopDescription(data.description);
        setCirriculumData(data.curriculum_section);
        setLoading(false);
        setSeats(data.seats);
        setIsUnlimitedSeats(data.is_unlimited_seats);
        setFaq(data.faq);
        setWorkshopExperts(data.workshop_experts);
        setIsActive(data.is_active);
        setWhatsappLink(data.whatsapp_group_url);
        setIsShowWorkshopCovers(data?.show_workshopcovers);
        setMetaTitle(data.meta_title || data.title);
        setMetaDescription(data.meta_description || data.description);
        setOgTitle(data.og_title || data.title);
        setOgDescription(data.og_description || data.description);
        setOgImage(data.og_image || data.work_shop_media[0]?.media);
      } else {
        setIsActive(false);
      }
    } catch (err) {
      console.error("Error fetching workshop details:", err);
      setLoading(false);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // useEffect(() => {
  //   getExpertDetails(expert);
  // }, [expert]);

  // const getExpertDetails = async (expert) => {
  //   try {
  //     const res = await open_api.get(`profiles/experts/${expert}/`);
  //     if (res?.status === 200 || res?.status === 201 || res?.status === 204) {
  //       setExpertDetails(res?.data);
  //     }
  //   } catch (err) {
  //     console.error("Error fetching expert details:", err);
  //   }
  // };

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [expandedSections, setExpandedSections] = useState({});
  const [showMore, setShowMore] = useState(false);

  const toggleSection = (index) => {
    setExpandedSections((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const handleShowMore = () => {
    setShowMore(true);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);

    const dayOfWeek = date.toLocaleString("en-US", { weekday: "long" });
    const day = date.getDate();
    const month = date.toLocaleString("en-US", { month: "short" });
    const year = date.getFullYear().toString().slice(-2);

    return `${dayOfWeek} ${day} ${month}' ${year}`;
  };

  const formatTime = (startDateTime, endDateTime) => {
    const start = new Date(startDateTime);
    const end = new Date(endDateTime);

    const formatOptions = { hour: "numeric", minute: "numeric", hour12: true };
    const startTime = start.toLocaleTimeString("en-US", formatOptions);
    const endTime = end.toLocaleTimeString("en-US", formatOptions);

    return `${startTime} - ${endTime}`;
  };

  const isEventPast = (endDateTime) => {
    const currentDateTime = new Date();
    const end = new Date(endDateTime);
    return currentDateTime > end;
  };

  const handlePlayButtonClick = () => {
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  const buttonText =
    workshopDetails.type?.title === "On Demand" ? "Buy Now" : "Register Now";

  const isLive = workshopDetails.type?.title === "Live Schedule";

  const workshopMrp =
    countryCode === "in" ? workshopDetails.mrp : workshopDetails.mrp_usd;
  const workshopSellingPrice =
    countryCode === "in"
      ? workshopDetails.selling_price
      : workshopDetails.selling_price_usd;
  const priceSymbol = countryCode === "in" ? "₹" : "$";
  const soldOut = !isUnlimitedSeats && seats === 0;
  const category1Media = workshopImage.find((media) => media.category === 1);
  const category2Media = workshopImage.find((media) => media.category === 2);

  const breadcrumbs = [
    { name: "Home", url: "https://www.myburgo.com/" },
    {
      name: workshopTitle,
      url: `https://www.myburgo.com/workshops/${workshopSlug}`,
    },
  ];

  if (isActive === false) {
    return <PageNotFound />;
  }

  return (
    <div>
      <Helmet>
        <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />
        <meta property="og:title" content={ogTitle} />
        <meta property="og:description" content={ogDescription} />
        <meta property="og:image" content={ogImage} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={window.location.href} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={ogTitle} />
        <meta name="twitter:description" content={ogDescription} />
        <meta name="twitter:image" content={ogImage} />
      </Helmet>
      <BreadcrumbSchema breadcrumbs={breadcrumbs} />

      {loading ? (
        <section className={styles.expertHeader}>
          <div className={styles.containerLeft}>
            <Skeleton variant="text" width={150} height={40} />
            <Skeleton variant="rectangular" width={"80%"} height={50} />
            <Skeleton variant="rectangular" width={"60%"} height={30} />
            <Skeleton variant="rectangular" width={"100%"} height={300} />
          </div>
          <div className={styles.containerRight}>
            <Skeleton variant="rectangular" width={"100%"} height={400} />
          </div>
        </section>
      ) : (
        <section className={styles.expertHeader}>
          <div className={styles.containerLeft}>
            <div className={styles.bgwithcolortext}>
              <span>{workshopDetails.type?.title}</span>
            </div>
            <h1 className={styles.workshopName}>{workshopTitle}</h1>
            <p className={styles.workshopDesc}>{workshopDescription}</p>
            {workshopDetails.type?.title === "Live Schedule" && (
              <div className={styles.dateContainer}>
                <div className={styles.dateIconContainter}>
                  <img
                    src={calender}
                    className={styles.dateIcon}
                    alt="calendar icon"
                  />
                  <div className={styles.date}>
                    {workshopDetails.is_date_only ? (
                      // Show date range without time if is_date_only is true
                      <>
                        {formatDate(workshopDetails.start_date_time)} -{" "}
                        {formatDate(workshopDetails.end_date_time)}
                      </>
                    ) : (
                      // Show date with time if is_date_only is false
                      formatDate(workshopDetails.start_date_time)
                    )}
                  </div>
                </div>
                {!workshopDetails.is_date_only && (
                  <div className={styles.clockIconContainer}>
                    <img
                      src={clock}
                      className={styles.clockIcon}
                      alt="clock icon"
                    />
                    <div className={styles.time}>
                      {formatTime(
                        workshopDetails.start_date_time,
                        workshopDetails.end_date_time
                      )}
                    </div>
                  </div>
                )}
              </div>
            )}

            <div className={styles.buttonChipContainer}>
              <div className={styles.buttonContainer}>
                <Button
                  variant="contained"
                  className={`${styles.allActionBtn} ${
                    soldOut ||
                    (isLive && isEventPast(workshopDetails.start_date_time))
                      ? styles.greyBtn
                      : ""
                  }`}
                  onClick={handleClickOpen}
                  disabled={
                    soldOut ||
                    (!isUnlimitedSeats
                      ? soldOut ||
                        (isLive && isEventPast(workshopDetails.start_date_time))
                      : isLive && isEventPast(workshopDetails.start_date_time))
                  }
                >
                  {soldOut ||
                  (isLive && isEventPast(workshopDetails.start_date_time))
                    ? "Sold Out"
                    : `${buttonText} at ${priceSymbol}${workshopSellingPrice}`}
                </Button>
                <p className={styles.specialPrice}>
                  special offer{" "}
                  <s>
                    {priceSymbol}
                    {workshopMrp}
                  </s>{" "}
                  {priceSymbol}
                  {workshopSellingPrice} only
                </p>
              </div>

              {isLive && !isUnlimitedSeats && (
                <div className={styles.seatsChip}>{seats} seats left</div>
              )}
              <PaymentDialog
                open={open}
                onClose={handleClose}
                image={media ? media.media : workshopImage}
                title={workshopTitle}
                expertIds={workshopExperts}
                description={workshopDetails.about_workshop}
                sellingPrice={workshopSellingPrice}
                mrpPrice={workshopMrp}
                workshop_id={workshopDetails.id}
                date={formatDate(workshopDetails.start_date_time)}
                time={formatTime(
                  workshopDetails.start_date_time,
                  workshopDetails.end_date_time
                )}
                isLive={isLive}
                whatsapplink={whatsapplink}
              />
            </div>
          </div>
          <VideoPlayer
            category1Media={category1Media}
            category2Media={category2Media}
          />
          <div className={styles.bgHeart}>
            <img src={bgheart} alt="background heart" />
          </div>
          <div className={styles.plane}>
            <img src={plane} alt="plane" />
          </div>
        </section>
      )}

      {isLive && !isEventPast(workshopDetails.start_date_time) && !soldOut ? (
        <Banner
          sellingprice={workshopSellingPrice}
          sellingmrp={workshopMrp}
          isLive={isLive}
          endTime={workshopDetails.start_date_time}
          handleClickOpen={handleClickOpen}
          workshopSlug={workshopSlug}
          priceSymbol={priceSymbol}
          isUnlimitedSeats={isUnlimitedSeats}
          seats={seats}
        />
      ) : null}

      {!isLive ? (
        <Banner
          sellingprice={workshopSellingPrice}
          sellingmrp={workshopMrp}
          isLive={isLive}
          endTime={workshopDetails.start_date_time}
          handleClickOpen={handleClickOpen}
          workshopSlug={workshopSlug}
          priceSymbol={priceSymbol}
          isUnlimitedSeats={isUnlimitedSeats}
          seats={seats}
        />
      ) : null}

      <section className={styles.workshopHelpContainer}>
        <h2 className={styles.CRHeading}>What you'll get</h2>
        <div className={styles.features}>
          {whyThisWorks.map((feature, index) => (
            <div key={index} className={styles.feature}>
              <div className={styles.icon}>
                <img src={feature.icon} alt={feature.title} />
              </div>
              <p>{feature.title}</p>
            </div>
          ))}
        </div>
      </section>

      <section className={styles.aboutWorkshop}>
        {workshopImage.map((media) =>
          media.category === 3 ? (
            <div className={styles.imageContent}>
              <img src={media.media} alt="Workshop" />
            </div>
          ) : null
        )}
        <div className={styles.textContent}>
          <h2 className={styles.CRHeading}>About the workshop</h2>
          <p className={styles.workshopDesc}>
            {workshopDetails.about_workshop}
          </p>
          <div className={styles.buttonChipContainer}>
            <div className={styles.buttonContainer}>
              <Button
                variant="contained"
                className={`${styles.allActionBtn} ${
                  soldOut ||
                  (isLive && isEventPast(workshopDetails.start_date_time))
                    ? styles.greyBtn
                    : ""
                }`}
                onClick={handleClickOpen}
                disabled={
                  soldOut ||
                  (!isUnlimitedSeats
                    ? soldOut ||
                      (isLive && isEventPast(workshopDetails.start_date_time))
                    : isLive && isEventPast(workshopDetails.start_date_time))
                }
              >
                {soldOut ||
                (isLive && isEventPast(workshopDetails.start_date_time))
                  ? "Sold Out"
                  : `${buttonText} at ${priceSymbol}${workshopSellingPrice}`}
              </Button>
              <p className={styles.specialPrice}>
                special offer{" "}
                <s>
                  {priceSymbol}
                  {workshopMrp}
                </s>{" "}
                {priceSymbol}
                {workshopSellingPrice} only
              </p>
            </div>
            {isLive && !isUnlimitedSeats && (
              <div className={styles.seatsChip}>{seats} seats left</div>
            )}
          </div>
        </div>
      </section>

      {workshopDetails.type?.title === "Live Schedule" && (
        <section className={styles.countersboxContainer}>
          <div className={styles.counterBox}>
            <div className={styles.dateContainerBanner}>
              <div className={styles.dateIconContainterBanner}>
                {/* Check if is_date_only is true */}
                {workshopDetails.is_date_only ? (
                  <div className={styles.dateText}>
                    <strong>From: </strong>
                    {formatDate(workshopDetails.start_date_time)}
                  </div>
                ) : (
                  <>
                    <img
                      src={bannerCalendar}
                      style={{ height: "80px" }}
                      alt="calendar icon"
                    />
                    <div className={styles.dateText}>
                      {formatDate(workshopDetails.start_date_time)}
                    </div>
                  </>
                )}
              </div>

              <div className={styles.clockIconContainerBanner}>
                {/* Check if is_date_only is true */}
                {workshopDetails.is_date_only ? (
                  <div className={styles.timeText}>
                    <strong>To: </strong>
                    {formatDate(workshopDetails.end_date_time)}
                  </div>
                ) : (
                  <>
                    <img
                      src={bannerClock}
                      style={{ height: "80px" }}
                      alt="clock icon"
                    />
                    <div className={styles.timeText}>
                      {formatTime(
                        workshopDetails.start_date_time,
                        workshopDetails.end_date_time
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </section>
      )}

      <section className={styles.workshopLearning}>
        <h2 className={styles.CRHeading}>What you’ll learn</h2>
        <div className={styles.pointsContainer}>
          <div className={styles.column}>
            {firstColumn.map((learnItem, index) => (
              <div key={index} className={styles.points}>
                <p>✔ {learnItem.title}</p>
              </div>
            ))}
          </div>
          <div className={styles.column}>
            {secondColumn.map((learnItem, index) => (
              <div key={index} className={styles.points}>
                <p>✔ {learnItem.title}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {!isLive && is_show_workshopcovers ? (
        <section className={styles.workshopCovers}>
          <h2 className={styles.CRHeading}>Workshop covers</h2>
          {curriculumData ? (
            <>
              <p>
                {curriculumData.length} sections •{" "}
                {curriculumData.reduce(
                  (total, section) =>
                    total + (section?.chapters ? section?.chapters?.length : 0),
                  0
                )}{" "}
                lectures
              </p>
              {curriculumData
                .slice(0, showMore ? curriculumData?.length : 3)
                .map((section, index) => (
                  <div className={styles.workshopCoversSection} key={index}>
                    <div
                      className={styles.workshopCoversSectionHeader}
                      onClick={() => toggleSection(index)}
                    >
                      <span>
                        Section {section.index}: {section.title}
                      </span>
                      <span className={styles.toggleIcon}>
                        {expandedSections[index] ? "-" : "+"}
                      </span>
                    </div>
                    {expandedSections[index] && (
                      <div className={styles.workshopCoversSectionContent}>
                        {section?.chapters.map((chapter, idx) => (
                          <div className={styles.workshopChapter} key={idx}>
                            <h4 style={{ fontWeight: 500 }}>
                              Chapter {chapter.index}: {chapter.title}
                            </h4>
                            {chapter.workshop_video.length > 0 ? (
                              chapter.workshop_video.map((video, vidIdx) => (
                                <div className={styles.lecture} key={vidIdx}>
                                  {workshopDetails.is_first_chapter_free ? (
                                    <div className={styles.lectureHeader}>
                                      <span
                                        className={styles.playIcon}
                                        onClick={handlePlayButtonClick}
                                      >
                                        <img
                                          src={playicon}
                                          alt="play icon"
                                          style={{
                                            width: "90%",
                                            paddingTop: "6px",
                                            cursor: "pointer",
                                          }}
                                        />
                                      </span>
                                      {isPopupOpen && (
                                        <VideoPopup
                                          videoUrl={video.video_url}
                                          onClose={handleClosePopup}
                                        />
                                      )}
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              ))
                            ) : (
                              <></>
                            )}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                ))}
              {!showMore && (
                <button
                  className={styles.showMoreButton}
                  onClick={handleShowMore}
                >
                  more sections
                </button>
              )}
            </>
          ) : (
            <p>Loading...</p>
          )}
        </section>
      ) : (
        <></>
      )}

      <div className={styles.buttonContainerCenter}>
        <Button
          variant="contained"
          className={`${styles.allActionBtnCenter} ${
            soldOut || (isLive && isEventPast(workshopDetails.start_date_time))
              ? styles.allActionBtnCenterGrey
              : ""
          }`}
          onClick={handleClickOpen}
          disabled={
            soldOut ||
            (!isUnlimitedSeats
              ? soldOut ||
                (isLive && isEventPast(workshopDetails.start_date_time))
              : isLive && isEventPast(workshopDetails.start_date_time))
          }
        >
          {soldOut || (isLive && isEventPast(workshopDetails.start_date_time))
            ? "Sold Out"
            : `${buttonText} at ${priceSymbol}${workshopSellingPrice}`}
        </Button>
        <p className={styles.specialPriceCenter}>
          special offer{" "}
          <s>
            {priceSymbol}
            {workshopMrp}
          </s>{" "}
          {priceSymbol}
          {workshopSellingPrice} only
        </p>
      </div>

      <section className={styles.workShopsContainer}></section>
      <section className={styles.workSslider}>
        <ExpertSection expertIds={workshopExperts} />
      </section>

      {workshopTestimonial?.length != 0 ? (
        <>
          <section className={styles.clientReviews}>
            <h2 className={styles.CRHeading}>What my clients say</h2>
            <div className={styles.InnerSlider}>
              <ClientreviewCard data={workshopTestimonial} />
            </div>
          </section>
        </>
      ) : null}

      {/* <section className={styles.recommendationWorkshop}></section>
      <section className={styles.BlogContainer}>
        <h2 className={styles.blogheading}>Browse through my Blogs</h2>
        <div className={styles.blogsInnerContainer}>
          <ExpertBlogSlider />
        </div>
      </section> */}
      {faq?.length != 0 ? (
        <section className={styles.faq}>
          <h2 className={styles.CRHeading}>Frequently asked questions</h2>
          <FAQ data={faq} />
        </section>
      ) : null}
      <AppPromotion />

      <div style={{ margin: "50px 0" }}>
        <JoinUsForm />
      </div>
    </div>
  );
};

const VideoPopup = ({ videoUrl, onClose }) => {
  return (
    <div className={styles.popupOverlay}>
      <div className={styles.popupContent}>
        <video
          controls
          autoPlay
          className={styles.videoPlayer}
          controlsList="nodownload noremoteplayback"
          disablePictureInPicture
          onContextMenu={(e) => e.preventDefault()} // Disable right-click menu for download options
        >
          <source src={videoUrl} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <button className={styles.closeButton} onClick={onClose}>
          Close
        </button>
      </div>
    </div>
  );
};

export default CourseDetailsPage;
