import React from "react";
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
  TelegramShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  WhatsappIcon,
  TelegramIcon,
} from "react-share";
import { FaShareAlt } from "react-icons/fa";
import styles from "./BlogInfo.module.css";
import { Rating } from "@mui/material";
import innerBLogBaby from "../../Assets/innerBLogBaby.png";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const handleInstagramShare = () => {
  const shareUrl = window.location.href;
  alert(
    "Instagram doesn't support direct sharing via web. Copy the link to share on Instagram."
  );
  navigator.clipboard.writeText(shareUrl); // Copy URL to clipboard
};

const handleShare = (platform) => {
  const shareUrl = window.location.href;
  const title = document.title;

  if (platform === "common") {
    if (navigator.share) {
      navigator
        .share({
          title: title,
          url: shareUrl,
        })
        .catch(console.error);
    } else {
      alert("Your browser doesn't support the Web Share API.");
    }
  }
};

const ArticleHeader = ({ data }) => {
  const isLoading = !data; // Check if data is still loading
  const shareUrl = window.location.href;
  const title = data?.title || "Article Title";

  return (
    <div className={styles.header}>
      <div className={styles.headerLeft}>
        {/* Title */}
        <h1>{isLoading ? <Skeleton width="70%" /> : title}</h1>

        {/* Meta Information */}
        <p className={styles.meta}>
          {isLoading ? (
            <Skeleton width="50%" />
          ) : (
            `${data?.published_date} • ${data?.read_time} min read`
          )}

          {/* Social Share Buttons */}
          <div className={styles.socialShare}>
            {isLoading ? (
              <Skeleton circle width={32} height={32} count={5} inline={true} />
            ) : (
              <>
                <FacebookShareButton url={shareUrl} quote={title}>
                  <FacebookIcon size={32} round />
                </FacebookShareButton>
                <TwitterShareButton url={shareUrl} title={title}>
                  <TwitterIcon size={32} round />
                </TwitterShareButton>
                <LinkedinShareButton url={shareUrl} title={title}>
                  <LinkedinIcon size={32} round />
                </LinkedinShareButton>
                <WhatsappShareButton url={shareUrl} title={title}>
                  <WhatsappIcon size={32} round />
                </WhatsappShareButton>
                <TelegramShareButton url={shareUrl} title={title}>
                  <TelegramIcon size={32} round />
                </TelegramShareButton>
                <FaShareAlt
                  className={styles.icon}
                  onClick={() => handleShare("common")}
                />
              </>
            )}
          </div>
        </p>

        {/* Author Section */}
        <div className={styles.author}>
          {/* Left Side: Circular Author Image */}
          {isLoading ? (
            <Skeleton
              circle
              width={50}
              height={50}
              className={styles.authorImage}
            />
          ) : (
            <img
              src={data?.authors?.[0]?.image || "default-author.jpg"} // Placeholder if image is not available
              alt={data?.authors?.[0]?.name || "Unknown Author"}
              className={styles.authorImage}
            />
          )}

          {/* Right Side: Author Name and Rating */}
          <div className={styles.authorDetails}>
            <p className={styles.authorName}>
              {isLoading ? <Skeleton width="80px" /> : data?.authors?.[0]?.name}
            </p>
            {isLoading ? (
              <Skeleton width="60px" height="25px" />
            ) : (
              <Rating name="read-only" value={5} readOnly />
            )}
          </div>
        </div>
      </div>

      {/* Right Side: Header Image */}
      <div className={styles.headerRight}>
        <div>
          {isLoading ? (
            <Skeleton width="100%" height={200} />
          ) : (
            <img
              className={styles.headerRightImage}
              src={
                data?.header_image_url?.url
                  ? data?.header_image_url?.url
                  : innerBLogBaby
              }
              style={{ width: "100%" }}
              alt="Blog Header"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ArticleHeader;
