import React, { useEffect } from "react";
import styles from "./index.module.css";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

function TermsAndCondition() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Helmet>
        <title>MyBurgo - Parenting App | Pregnancy App | SelfCare App</title>
        <meta
          name="description"
          content="MyBurgo is India's comprehensive parenting app offering expert guidance, interactive workshops, personalized resources, and community support for parents and pregnant women. Empower your parenting journey with child development tools, curated recipes, mental health support, and more."
        />
      </Helmet>
      <div className={styles.mainContainer}>
        <h1> TERMS OF SERVICE</h1>
        <p>
          The website, www.myburgo.com along with the corresponding mobile
          application (&quot;Portal&quot;, “us” or &quot;we&quot;). This Portal
          is managed and operated by Velvin Technologies Private Limited, a
          company incorporated under the laws of India, having its registered
          office at 3rd floor, Court House, Lokmanya Tilak Marg, Dhobi Talao,
          Mumbai – 40000 B-303, Mangalya, Marol Maroshi Road, Andheri (East),
          Mumbai-400059, India (“we”, “Company”, “us” or “our”),
        </p>
        <p>
          The terms and conditions as set out herein (“Terms”) constitutes an
          agreement between the Company, and a natural or legal person who
          accesses and/or uses the Portal in any manner (“you” or “your”).
        </p>
        <p>
          The Portal is a platform that provides comprehensive support for new
          mothers and young children, offering counselling, therapy, postpartum
          care, and child development services. (“Services”).
        </p>
        <p>
          By using the Portal, you expressly agree to be bound by the Terms. If
          you do not agree with any of the Terms, please refrain from using the
          Portal. Please note that your usage of the Portal, the Services,
          offers or promotions in relation to the Services as may be provided by
          us or our affiliates or third parties, shall be governed by these
          Terms and such other terms that may be applicable (“Additional
          Terms”). If these Terms are inconsistent with any Additional Terms,
          the Additional Terms will supersede to the extent of the Additional
          Terms are applicable.
        </p>
        <p>
          This document is an electronic record in terms of Information
          Technology Act, 2000 (“IT Act”), the applicable rules thereunder and
          the provisions pertaining to electronic records in various statutes as
          amended by the IT Act. This electronic record is generated by a
          computer system and does not require any physical or digital
          signatures.
        </p>

        <p>
          Any individual who can enter into legally binding contracts as per
          Indian Contract Act, 1872, i.e. individuals who are 18 years of age or
          older, are of sound mind, and are not disqualified from entering into
          contracts by any law, can use and access the Portal.
        </p>
        <h2>1. GENERAL</h2>
        <p>
          Your access, or otherwise any usage of the Portal means you agree to
          these Terms read with all the policies that may be available on the
          Portal (collectively, the “Agreement”).
        </p>
        <h2>2. REGISTRATION OF YOUR ACCOUNT</h2>
        <ul>
          <li>
            In order to access or use the Portal you must set up an account by
            providing information about yourself as prompted on the sign-up page
            of the Portal which may include your name, gender, mobile number,
            date of birth, city, emailId and such other details relevant for
            placing orders through your account. Your login ID and password will
            be created basis the information provided by you, which you can use
            to access your account at any time. Your credentials shared by you
            at the time of registration and your login ID and password are
            referred to as “Account Information”.
          </li>
          <li>
            You are solely authorized to operate the account created by you.
            Consequently, it is your responsibility to maintain the
            confidentiality of your Account Information. You shall remain solely
            responsible for all the actions by you on your account.
          </li>
          <li>
            You will: (a) immediately inform us of any unauthorized use or
            security breach of your account, and (b) ensure that you log out of
            your account at the end of each session. You may be held responsible
            for any losses incurred to Company or any other user due to
            unauthorized use of their account, resulting from your failure to
            keep your Account Information safe, secure and confidential. We
            reserve the right to refuse access to the Portal, terminate or
            suspend accounts, remove or edit content at any time without
            providing any notice to you.
          </li>
          <li>
            We constantly monitor the user’s account in order to avoid
            fraudulent accounts and transactions. Users with more than one
            account or availing referral vouchers fraudulently shall be liable
            for legal actions under the applicable law and we reserve the right
            to recover the cost of Services, transaction fees, or any other
            related cost including attorney fees, from such person using the
            Portal fraudulently. In an event we detect any fraudulent or failed
            transaction, prior to initiation of legal action, we reserve the
            right to suspend or delete such account with immediate effect and
            dishonour all past and pending orders without any liability on our
            part. However, this shall not absolve the User from any liability.
          </li>
        </ul>
        <h2>3. COMMUNICATION AND UNSUBSCRIPTION</h2>
        <ul>
          <li>
            You understand that once you have shared your Account Information
            and successfully registered on the Portal, you may receive SMS or
            email notifications from us relating to your registration,
            promotions and transactions on the Portal.
          </li>
          <li>
            You consent to receive communications, notifications and commercial
            messages regarding any transaction / marketing / promotional
            activities that are related to your transaction on the Portal or the
            Services that maybe available on the Portal from time to time. By
            sharing your Account Information and registering on the Portal and/
            or verifying your contact number with us, you explicitly consent to
            receive marketing / promotional communications (through call, SMS,
            email or other digital and electronic means) from us and/or our
            authorized representatives regarding any new services or offerings.
          </li>
          <li>
            While the Company endeavours to provide these notifications and
            reminders to you promptly, the Company does not provide any
            guarantee and will not be held liable or responsible for any failure
            to send such notifications/reminders to you.
          </li>
          <li>
            You can unsubscribe/opt-out from receiving marketing/promotional
            communications, newsletters and other notifications from the Company
            at any time by following the instructions set out in such
            communications.
          </li>
        </ul>
        <h2>4. USER WARRANTIES AND RESTRICTIONS</h2>
        <ul>
          <li>
            You represent and warrant that: (a) your use of the Portal will not
            violate any applicable law or regulation; (b) all information that
            is submitted to the Company in connection with the Portal is true,
            accurate and lawful; (c) Your use of the Portal does not breach any
            applicable policies or guidelines and will not cause injury to any
            person or entity. If at any time, the information provided by you is
            found to be false or inaccurate the Company will have the right to
            reject registration, cancel any or all orders, and restrict you from
            using the Portal and other affiliated services in the future without
            any prior intimation whatsoever. You agree to indemnify the Company
            and its affiliates for all claims brought by a third party.
          </li>
          <li>
            You will use the Portal for a lawful purpose only, and will not
            undertake any activity that is harmful to the Company or the Portal
            or its content or otherwise not envisaged through the Portal. You
            have a limited license to access and use the Portal, solely for the
            purpose of availing the Services, subject to these Terms.
          </li>
          <li>
            The use of the Portal by you is restricted to personal use only, and
            you will not use any portion of this Portal or Services for any
            resale or commercial purpose.
          </li>
          <li>
            You will not do any of the following:
            <ul>
              <li>
                Use any engine, software, tool, agent or other mechanism to
                navigate or search the Portal;
              </li>
              <li>
                Make false or malicious statements against the Services, the
                Portal or the Company;
              </li>
              <li>
                Post, copy, submit, upload, distribute, or otherwise transmit or
                make available any software or other computer files that contain
                a virus or other harmful component, or otherwise disrupt or
                damage the Portal or any connected network, or otherwise
                interfere with any person or entity&#39;s use or enjoyment of
                the Portal;
              </li>
              <li>
                Introduce any trojans, viruses, any other malicious software,
                any bots or scrape the Portal for any information;
              </li>
              <li>
                Probe, scan, or test the vulnerability of any system, security
                or authentication measures implemented by us or otherwise tamper
                or attempt to tamper with our technological design and
                architecture;
              </li>
              <li>
                Hack into or introduce malicious software of any kind onto the
                Portal;
              </li>
              <li>
                Engage in any form of antisocial, disruptive, or destructive
                acts, including “flaming,” “spamming,” “flooding,” “trolling,”,
                “phishing” and “griefing” as those terms are commonly understood
                and used on the internet.
              </li>
            </ul>
          </li>
        </ul>
        <p>
          You are prohibited from hosting, displaying, uploading, modifying,
          publishing, transmitting, updating or sharing on or through the
          Portal, any information that (i) belongs to another person and to
          which You do not have any right; (ii) is harmful, harassing,
          blasphemous, defamatory, obscene, pornographic, paedophilic, invasive
          of another’s privacy, hateful, relating to or encouraging money
          laundering or gambling, or otherwise harmful in any manner whatsoever;
          (iii) harms minors in any way; (iv) infringes any patent, trademark,
          copyright or other proprietary rights; (v) violates any law for the
          time being in force; (vi) deceives or misleads the addressee about the
          origin of such messages or communicates any information which is
          grossly offensive or menacing in nature; (vii) impersonates or defames
          another person; or (viii) contains software viruses or any other
          computer code, files or programs designed to interrupt, destroy or
          limit the functionality of any computer resource.
        </p>

        <h2>6. ACCESS OUTSIDE THE REPUBLIC OF INDIA</h2>
        <ul>
          <li>
            All information, content, material, text, graphics, images, logos,
            button icons, software code, interface, design and the collection,
            arrangement and assembly of the content on the Portal are the
            property of the Company or its affiliates (“Company Content”), and
            are protected under copyright, trademark and other applicable laws.
            Further, all trademarks, services marks, trade names and trade
            secrets in relation to the Portal whether or not displayed on the
            Portal, are proprietary to the Company. Company may use artificial
            intelligence tools to generate, curate, or assist in the creation of
            the Company Content on the Portal, including but not limited to
            text, graphics, images, and other creative materials.
          </li>
          <li>
            Company may display on the Portal any third-party logos, trade
            names, trademarks of other brands, as per the license granted to the
            Company by such brands.
          </li>
          <li>
            No information, content or material from the Portal (including the
            Company Content) may be copied, reproduced, republished, duplicated,
            copied, sold, resold, uploaded, posted, transmitted, distributed or
            otherwise exploited in any way (including for any commercial
            purpose) without Company’s express written permission. You will not
            frame or use framing techniques to enclose any trademark, logo, or
            other proprietary information (including images, text, page layout,
            or form) of the Company and its affiliates without express written
            consent. You may not use any meta tags or any other “hidden text”
            utilizing the Company’s or its affiliates’ names or trademarks
            without the express written consent of the Company and/or its
            affiliates, as applicable. Any unauthorized use terminates the
            permissions granted in these Terms.
          </li>
          <li>
            Any information regarding your child’s progression, development or
            ability to perform the exercises or activities provided by our
            Portal is meant to be suggestive in nature as it is impossible to
            determine every child’s proper developmental progression as each
            child develops in a different manner and pace. You are solely
            responsible for determining your child’s developmental process and
            whether it is appropriate and safe to conduct any suggested
            activities and exercise with your child. If you have any suspicion
            of a developmental delay or any other health issue with your child,
            you should consult a physician or appropriate professional
            immediately. You are solely responsible for determining whether your
            child is physically or mentally able to perform any of the exercises
            or activities suggested on our Portal. We strongly urge you to
            consult a doctor or appropriate healthcare provider before using any
            information obtained in our Portal, especially if your child has a
            special medical condition.
          </li>
          <li>
            You are solely responsible for determining if your child requires
            treatment from a certified health professional. If at any time
            during the performance of any activity or exercise suggested on our
            Portal, your child experiences any fatigue, increased or irregular
            heartbeat, pain, distress, crying, uncomfortableness, unwillingness
            or hesitation to perform, vomiting, vertigo, fainting, rash, limited
            range of motion, tenderness, swelling, pain, unusual sweating, or
            any other negative or detrimental response, stop immediately and
            consult a doctor. Do not resume performance of any of our suggested
            activities or exercises until obtaining prior approval from your
            child’s doctor. Never disregard professional medical advice or delay
            in seeking it for any reason. The exercises, activities, methods,
            products, procedures and opinions on our Portal and are solely meant
            to be used for informational purposes only and are not meant to
            provide any guaranteed results.
          </li>
          <li>
            Company emphasizes the use of child-safe materials and toys around
            your child. You are solely responsible for making sure that any
            materials or toys used during our suggested activities or exercises
            are safe for your child. Avoid using materials or toys that may
            present a choking hazard and always monitor your child closely when
            using toys as many items can ultimately present a choking hazard.
            Activities or exercises with your child should always be performed
            by a competent adult in a safe environment to prevent injuries. Care
            should be exercised to reduce the risk of injury if the child falls
            down or doesn’t perform the activity or exercise in the expected or
            intended fashion. Activities or exercises suggested for the car must
            be performed by a competent adult who is not driving while the
            vehicle (i) is being driven at a safe speed and in a safe manner or
            (ii) is stopped. Company cannot possibly foresee any and every
            possible risk to your child from the performance or participation in
            its suggested activities or exercises, and your use of good judgment
            and common sense is by far the best possible deterrent of injury to
            your child.
          </li>
          <li>
            Use common sense when you use our Portal or any related third party
            website, content or applications. The Portal is for informational
            purposes only. Despite our efforts to provide useful and accurate
            information, errors may appear from time to time. Some of the
            material you find on our Portal was not created, edited, or posted
            by us (for example, discussions or group postings). The materials
            posted by Users or other third parties on our Portal represent the
            views of such Users or other third parties and are not endorsed by
            us, nor do we guarantee the accuracy of any such material. Though we
            hope the advertisers, merchants and Users you meet through our
            Portal treat you honourably and fairly, we cannot vouch for the
            information, goods, or services they offer. Before you act on
            information you have found on or through any of our Portal, confirm
            any facts that are important to your decision. We encourage you to
            exercise a healthy skepticism and good judgment.
          </li>
        </ul>
        <h2>8. USER SUBMISSIONS AND IDEAS</h2>
        <ul>
          <li>
            Responsibility for what is posted in the discussion groups or other
            public forums on our Portal lies with each User. You alone are
            responsible for the content of your messages, and the consequences
            of any such messages. You agree not to use our Portal to send or
            submit materials that: (a) are false, inaccurate or misleading; (b)
            infringe any third party’s copyright, patent, trademark, trade
            secret or other proprietary rights or rights of publicity or
            privacy; (c) violate any law, statute, ordinance or regulation
            (including without limitation those governing export control,
            consumer protection, unfair competition, anti-discrimination and
            false advertising); (d) are libelous, threatening or harassing; (e)
            are obscene or contain any kind of pornography; (f) contain any
            viruses, trojan horses, spyware, worms, time bombs, cancelbots or
            other computer programming routines that are intended to damage,
            detrimentally interfere with, surreptitiously intercept or
            expropriate any system, data or personal information; (g) might
            create liability for Company or might cause us to lose (in whole or
            in part) the services of our internet service providers or other
            suppliers; (h) interfere with the ability of others to enjoy our
            Portal; (i) impersonate any other person or entity, whether actual
            or fictitious, including impersonating an employee or consultant of
            Company; (j) link to or include descriptions of goods or services
            that: (i) are prohibited under this Agreement; or (ii) you do not
            have a right to link to or include; (k) contain any content that you
            do not have the right to make available under any law or otherwise,
            including any contractual or fiduciary relationship (such as inside
            information or confidential information learned under a
            non-disclosure agreement); or (l) instigate or encourage others to
            commit illegal activities or cause injury or property damage to any
            person. We neither endorse nor guarantee the accuracy or propriety
            of any submission by a User of other third-party. We do, however,
            reserve the right, but do not assume the obligation, to restrict or
            prohibit your use of our Portal if we believe you are violating any
            of the terms of this Agreement and to remove, edit, or relocate any
            submission as we see fit, whether for legal or other reasons.
          </li>
          <li>
            You may choose to or we may invite you to submit comments or ideas
            about our Portal about how to improve them (“Ideas”). By submitting
            any Idea, you agree that your disclosure is gratuitous, unsolicited
            and without restriction and will not place Company under any
            obligation whatsoever, and that we are free to use the Idea without
            any additional compensation to you, and/or to disclose the Idea to
            anyone. You further acknowledge that, by acceptance of your
            submission, Company does not waive any rights to use similar or
            related ideas previously known to us, or developed by our employees
            or affiliates, or obtained from sources other than you.
          </li>
        </ul>
        <h2>9. THIRD-PARTY SERVICES</h2>
        <ul>
          <li>
            We may provide links to third-party websites, applications or
            content. Those third-party websites, applications or content and the
            parties that control them are beyond our control. We make no
            representations as to the content, quality, suitability,
            functionality or legality of any third-party websites, applications
            or content to which we may provide links, and you hereby waive any
            claim you might have against Company, its officers, directors,
            managers, employees, agents, servants, contractors, affiliates,
            licensors, parents, subsidiaries, shareholders,owners, members, and
            any other affiliated companies, entities or persons (other than the
            providers of the third-party websites) with respect to such
            websites, applications or content. You may order goods or services
            through links on our Portal from third parties. All matters
            concerning such goods or services, including, but not limited to,
            purchase terms, payment terms, warranties, guarantees, maintenance
            and delivery, are solely between you and the third parties with whom
            you chose to do business. We make no warranties or representations
            whatsoever with regard to any goods or services provided by those
            third parties. You will not consider us – nor will we be deemed – a
            party to such transactions, whether or not we may have received some
            form of remuneration in connection with the transaction. We will not
            be liable for any costs or damages arising out of or relating to any
            transaction (whether directly or indirectly) between you and any
            other person or entity.
          </li>
          <li>
            COMPANY DOES NOT WARRANT, ENDORSE, RECOMMEND, GUARANTEE, OR ASSUME
            RESPONSIBILITY FOR ANY GOOD OR SERVICE ADVERTISED OR OFFERED BY A
            THIRD PARTY THROUGH OUR PORTAL, OR ANY HYPERLINKED THIRD-PARTY
            WEBSITE, APPLICATION, GOOD OR SERVICE. COMPANY RESERVES THE RIGHT,
            BUT HAS NO OBLIGATION, TO MONITOR ANY TRANSACTION AND/OR
            COMMUNICATION BETWEEN YOU AND THIRD-PARTY PROVIDERS OF GOODS OR
            SERVICES.
          </li>
          <li>
            We use third-party services such as Google Analytics and Microsoft
            Clarity to track user behavior on our platform. These services help
            us analyze user activity, improve the functionality of the platform,
            and enhance user experience. By using our platform, you consent to
            the collection and processing of data by these third parties in
            accordance with their privacy policies. We do not control the data
            collection practices of these third parties, and they may use
            cookies, web beacons, or other technologies to collect information
            about your use of our platform. You can review the privacy policies
            of these third parties to understand how your data is handled:
            <a href="https://policies.google.com/technologies/partner-sites">
              Google Analytics Privacy Policy
            </a>{" "}
            <a href="https://www.microsoft.com/en-US/privacy/privacystatement">
              Microsoft Clarity Privacy Policy
            </a>
            You may opt out of certain tracking features by disabling cookies in
            your browser settings, though this may affect the functionality of
            our platform.
          </li>
        </ul>
        <h2>10. LICENSE AND USE OF YOUR CONTENT</h2>
        <ul>
          <li>
            Your use of the Portal, the Services, and access to the Company
            Content is subject to a limited, revocable and non-exclusive license
            which is granted to you when you register on the Portal.
          </li>
          <li>
            The license granted to you does not include a license for: (a) any
            use of the Portal, the Services and/or of the Company Content other
            than as contemplated in these Terms, (b) any downloading or copying
            of Account Information, (c) any use of data mining, robots, or
            similar data gathering and extraction tools to extract (whether once
            or many times) any parts of the Portal, (d) creating and/ or
            publishing your own database that features parts of the Portal.
          </li>
          <li>
            You grant to the Company a royalty-free, perpetual, irrevocable,
            non-exclusive right and license to adopt, publish, reproduce,
            disseminate, transmit, distribute, copy, use, create derivative
            works from, display worldwide, or act on any material posted by you
            on the Portal without additional approval or consideration in any
            form, media, or technology now known or later developed, for the
            full term of any rights that may exist in such content, and you
            waive any claim over all feedback, comments, ideas or suggestions or
            any other content provided through or on the Portal. You agree to
            perform all further acts necessary to perfect any of the above
            rights granted by you to the Company, including the execution of
            deeds and documents, at its request.
          </li>
          <li>
            You agree not to engage in any of the following prohibited
            activities: copying, distributing, or disclosing the Portal in any
            medium, including without limitation by any automated or
            non-automated “scraping”; using any automated system, including
            without limitation “robots,” “spiders,” “offline readers,” etc., to
            access our Portal in a manner that sends more request messages to
            the servers we use than a human can reasonably produce in the same
            period of time by using a conventional on-line web browser (except
            that Company grants the operators of public search engines revocable
            permission to use spiders to copy information from Portal for the
            sole purpose of creating publicly available searchable indices of
            the materials); transmitting spam, chain letters, or other
            unsolicited email; attempting to interfere with, compromise our
            system integrity or security or decipher any transmissions to or
            from the servers we use; taking any action that imposes, or may
            impose at our sole discretion an unreasonable or disproportionately
            large load on our infrastructure; uploading invalid data, viruses,
            spyware, worms, or other software agents; collecting or harvesting
            any personally identifiable information, including account names,
            from the servers we use; using our Portal for any commercial or
            solicitation purposes; impersonating another person or otherwise
            misrepresenting your affiliation with a person or entity, conducting
            fraud, hiding or attempting to hide your identity; interfering with
            the proper working of our Portal; accessing our Portal through any
            technology or means other than those provided or authorized by the
            Company; bypassing the measures we may use to prevent or restrict
            access to our Portal, including without limitation features that
            prevent or restrict use or copying of any content or enforce
            limitations on use of our Portal.
          </li>
        </ul>

        <h2>11. INDEMNIFICATION AND LIMITATION OF LIABILITY</h2>
        <p>
          You agree to indemnify, defend and hold us harmless our affiliates,
          officers, directors, employees, consultants, licensors, agents, and
          representatives from and against any and all third- party claims,
          losses, liabilities, damages, and/or costs (including reasonable
          attorney fees and costs) arising from your access to or use of the
          Portal, violation of these Terms, or infringement of any of our or any
          third-party intellectual property or other rights. We will notify you
          promptly of any such claim, loss, liability, or demand, and in
          addition to the foregoing, you agree to provide us with reasonable
          assistance, at your expense, in defending any such claim, loss,
          liability, damage, or cost. To the maximum extent permitted by
          applicable law, in no event shall Company, its officers, directors,
          managers, employees, agents, servants, contractors, affiliates,
          licensors, parents, subsidiaries, shareholders, owners, members or any
          other affiliated companies, entities or persons be liable under any
          theory of liability, including under contract, tort, negligence or
          otherwise for any indirect, punitive, incidental, special,
          consequential, exemplary, or similar damages, including without
          limitation damages for loss of profits, goodwill, use, data or other
          intangible losses, that result from the use of, or inability to use,
          our Portal or any information, materials, goods and services provided
          by our affiliates, licensors, users or other third parties, even if we
          are advised beforehand of the possibility of such damages. Under no
          circumstances will we be responsible for any damage, loss or injury
          resulting from hacking, tampering or other unauthorized access or use
          of our Portal or your account or the information contained therein. In
          no event shall Company, its officers, directors, managers, employees,
          agents, servants, contractors, affiliates, licensors, parents,
          subsidiaries, shareholders, owners, members or any other affiliated
          companies, entities or persons be liable to you for any claims in an
          amount exceeding the amount you paid to Company hereunder or INR 1000,
          whichever is greater. This limitation of liability section applies
          whether the alleged liability is based on contract, tort, negligence,
          strict liability, or any other basis, even if we have been advised of
          the possibility of such damage. The foregoing limitation of liability
          shall apply to the fullest extent permitted by law in the applicable
          jurisdiction.
        </p>
        <h2>12. MISCELLANEOUS</h2>
        <ul>
          <li>
            <strong>Force Majeure:</strong> We will not be held responsible for
            any delay or failure to comply with its obligations if the delay or
            failure arises from any cause which is beyond our reasonable
            control.
          </li>
          <li>
            <strong>Waiver:</strong> No provision in these Terms will be deemed
            waived and no breach excused, unless such waiver or consent is in
            writing and signed by us. Any consent by us to, or waiver of your
            breach, whether expressed or implied, will not constitute consent
            to, waiver of, or excuse for any other different or subsequent
            breach.
          </li>
          <li>
            <strong>Severability:</strong> If any provision of these Terms is
            held by a court of competent jurisdiction to be unenforceable under
            applicable law, then such provision will be excluded from these
            Terms and the remainder of these Terms will be interpreted as if
            such provision were so excluded and will be enforceable in
            accordance with its terms; provided that, in such event, these Terms
            will be interpreted so as to give effect, to the greatest extent
            consistent with and permitted by applicable law, to the meaning and
            intention of the excluded provision as determined by such court of
            competent jurisdiction.
          </li>
          <li>
            <strong>Amendment:</strong> These Terms are subject to amendments
            and modifications and may be updated from time to time, without any
            advance notice. You are requested to regularly review the Terms as
            available on the Portal. Your relationship with the Portal will be
            governed by the most current version of these Terms, as published on
            the Portal.
          </li>
        </ul>
        <h2>13. GOVERNING LAW AND DISPUTE RESOLUTION</h2>
        <p>
          All disputes and claims arising out of these Terms in relation to any
          or all transactions on the Portal is governed by the laws of India and
          shall be subject to exclusive jurisdiction of the competent courts of
          Mumbai.
        </p>
        <h2>14. GREIVANCE REDRESSAL</h2>
        <p>
          If You have questions or concerns or grievances regarding this Privacy
          Policy, You can email Us at{" "}
          <a href="mailto:hello@myburgo.com">hello@myburgo.com</a>.
        </p>
      </div>
    </>
  );
}

export default TermsAndCondition;
